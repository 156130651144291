export default {
  set (name, value, expires = 2) {
    const date = new Date();
    date.setTime(date.getTime() + expires * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};path=/;${'expires=' + date.toUTCString()};SameSite=Strict`;
  },

  get (name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match.pop();
  },

  delete (name) {
    this.set(name, "", { 'max-age': -1 })
  }
}