<template>
	<div class="questionaire-wrapper w-full">
		<div class="questionaire__sidebar max-w-xs">
			<div class="floating-piece">
				<div class="mobile-left">
					
					<!-- <div class="deal-header">
						<div class="deal-icon"><img :src="icon" :alt="icon"></div>
						<div class="deal-header-info">
							<div class="deal-name text-dark-grey-blue">{{ name }}</div>
							<div class="progress-bar-wrap small-only">
								<div class="progress-bar">
								<div class="progress-bar_done" :style="{ 'width': `${roundedProgress}%` }"></div>
								</div>
								<div class="progress-bar-legend">{{ roundedProgress }}%</div>
							</div>
						</div>
					</div>
					<div class="flex items-center mt-2">
						<div class="progress-bar-wrap big-only mr-3 ">
							<div class="progress-bar">
							<div class="progress-bar_done" :style="{ 'width': `${roundedProgress}%` }"></div>
							</div>
							<div class="progress-bar-legend">{{ roundedProgress }}%</div>
						</div>
						<router-link to="/">
							<button class="bg-dark-indigo font-medium whitespace-nowrap px-4 py-2 text-white text-sm rounded-md">
								Save <span class="big-only">and</span><span class="small-only">&</span> Exit
							</button>
						</router-link>
					</div> -->
					
				</div> 
				
				<div class="text-lg font-bold text-indigo text-dark-grey-blue mb-2">Your path to a better, smarter commercial mortgage</div>
				<p class="text-sm">
					After processing billions of dollars of loans from tens of thousands of borrowers, we've got this down to a science.
				</p>

				<ol class="mt-5 ml-2 relative border-l border-gray-200 dark:border-gray-700">                  
					<li class="mb-5 ml-6">            
						<span class="flex absolute -left-3 justify-center items-center w-6 h-6 bg-blue-100 rounded-full dark:bg-blue-900">
							<svg class="w-4 h-4 text-blue-600 dark:text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
						</span>
						<h3 class="flex items-center mb-1 text-md font-semibold  text-dark-grey-blue dark:text-white">Get to know us</h3>
						<p class="mb-4 text-sm font-normal text-gray-500 dark:text-gray-400">Look through our site, setup an account, get started.</p>
					</li>
					<li class="mb-5 ml-6">
						<span class="flex absolute -left-3 justify-center items-center w-6 h-6 bg-blue-200 rounded-full dark:bg-blue-900">
							<span class="text-xs font-bold text-blue-600 dark:text-blue-400">2</span>
						</span>
						<h3 class="mb-1 text-md font-semibold  text-dark-grey-blue dark:text-white">Build your loan request</h3>
						<p class="text-sm font-normal text-gray-500 dark:text-gray-400">Help us understand your deal by sharing information with us in our intuitive loan portal.</p>
					</li>
					<li class="mb-5 ml-6 opacity-60 hover:opacity-100 cursor-default">
						<span class="flex absolute -left-3 justify-center items-center w-6 h-6 bg-blue-50 rounded-full dark:bg-blue-900">
							<span class="text-xs font-bold text-blue-600 dark:text-blue-400">3</span>
						</span>
						<h3 class="mb-1 text-md font-semibold  text-dark-grey-blue dark:text-white">Upload documentation</h3>
						<p class="text-sm font-normal text-gray-500 dark:text-gray-400">Add supporting documentation to ensure we've got a complete package for us to analyze.</p>
					</li>
					<li class="mb-5 ml-6 opacity-60  hover:opacity-100 cursor-default">
						<span class="flex absolute -left-3 justify-center items-center w-6 h-6 bg-blue-50 rounded-full dark:bg-blue-900">
							<span class="text-xs font-bold text-blue-600 dark:text-blue-400">4</span>
						</span>
						<h3 class="mb-1 text-md font-semibold  text-dark-grey-blue dark:text-white">Marketplace submission</h3>
						<p class="text-sm font-normal text-gray-500 dark:text-gray-400">Your capital markets advisor will work with our AI to match you to the perfect loan.</p>
					</li>
					<li class="mb-5 ml-6 opacity-60  hover:opacity-100 cursor-default">
						<span class="flex absolute -left-3 justify-center items-center w-6 h-6 bg-blue-50 rounded-full dark:bg-blue-900">
							<span class="text-xs font-bold text-blue-600 dark:text-blue-400">5</span>
						</span>
						<h3 class="mb-1 text-md font-semibold  text-dark-grey-blue dark:text-white">Choose the best deal</h3>
						<p class="text-sm font-normal text-gray-500 dark:text-gray-400">You'll work directly with your advisor or the right lender to select the best deal for you.</p>
					</li>
				</ol>
			</div>
		</div>
		<div class="questionaire max-w-5xl" :class="{'outstanding-only': outstadingOnly}">
			<div class="questionaire__navigation !mb-5">
				<div class="flex w-full justify-between pb-4 ">
					<div class="deal-header">
						<div class="deal-icon"><img :src="icon" :alt="icon"></div>
						<div class="deal-header-info">
							<div class="deal-name text-dark-grey-blue">{{ name }}</div>
							<div class="progress-bar-wrap small-only">
								<div class="progress-bar">
								<div class="progress-bar_done" :style="{ 'width': `${roundedProgress}%` }"></div>
								</div>
								<div class="progress-bar-legend">{{ roundedProgress }}%</div>
							</div>
						</div>
					</div>
					<div class="flex items-center w-1/2">
						<div class="progress-bar-wrap big-only mr-3 ">
							<div class="progress-bar">
							<div class="progress-bar_done" :style="{ 'width': `${roundedProgress}%` }"></div>
							</div>
							<div class="progress-bar-legend">{{ roundedProgress }}%</div>
						</div>
						<router-link to="/">
							<button class="bg-dark-indigo font-medium whitespace-nowrap px-4 py-2 text-white text-sm rounded-md">
								Save <span class="big-only">and</span><span class="small-only">&</span> Exit
							</button>
						</router-link>
					</div>
				</div>
				<!-- <div class="left">
					<a
						href="#showQuestions"
						@click.prevent="activeTab = 'questions'"
						:class="{ 'is-active': activeTab == 'questions' }"
					>
						Questions
					</a>
					<a
						href="#showDocuments"
						@click.prevent="activeTab = 'documents'"
						:class="{
							'is-active': activeTab == 'documents',
							'is-disabled': documentsList.length < 1
						}"
					>
						Documents
						<div class="docs-left" v-if="documentsLeft">{{ documentsLeft }}</div>
					</a>
				</div>
				<div class="right">
					<div class="switch-wrap" @click="toggleOutstanding()">
						<span class="big-only">Show outstanding items only</span>
						<div class="switch-btn">
							<div class="switch big-only" :class="{'is-active': outstadingOnly}"><div class="circle"></div></div>
							<div class="mobile-switch small-only" :class="{'is-active': outstadingOnly}">
								<span v-if="outstadingOnly">Show completed</span>
								<span v-else>Hide completed</span>
							</div>
						</div>
					</div>
				</div> -->
			</div>
			
			

			<template v-if="activeTab == 'questions'">
				<questionnaire-question
					v-for="(question, index) in questionsList"
					:question="question"
					:key="question._id + index"
					:index="index"
					:deal="deal"
					:isCurrent="index == activeQuestion"
					:isLastQuestion="isLastQuestion(question)"
					:data-index="index"
					:estimatedProgress="estimatedProgress"
					:roundedEstimatedProgress="roundedEstimatedProgress"
					@question-saved="updateQuestionsList"
					@active-question-changed="setActiveQuestion"
				/>
			</template>

			<template v-else>
				<questionnaire-documents :deal="deal" :documents="documentsList" @document-updated="handleDocumentUpdate" />
			</template>
		</div>
	</div>
</template>



<script>

	import { mapGetters, mapActions } from 'vuex'
	import mixpanel from '@/helpers/mixpanel.js'

	import QuestionnaireQuestion from '@/components/QuestionnaireQuestion'
	import QuestionnaireDocuments from '@/components/QuestionnaireDocuments'

	import { getFirstQuestion, getNextQuestion } from '@/helpers/questionnaire.js'

	import dealStats from '@/mixins/deal-stats.js'
	
	export default {
		name: 'Questionnaire',

		components: {
			QuestionnaireQuestion,
			QuestionnaireDocuments
		},

		data () {
			return {
				questionsList: [],
				activeTab: 'questions',
				outstadingOnly: false,
				activeQuestion: -1,
			}
		},

		mixins: [
			dealStats
		],

		props: {
			deal: {
				type: Object,
				required: true
			}
		},

		computed: {
			initialFlow () {
				return this.deal.type == 'Business' ? 'sba-flow' : 'property-flow'
			},

			flows () {
				return this.deal.flows
			},

			...mapGetters([
				'questions'
			]),

			documentsList () {
				return this.questionsList.filter(question => {
					return question.type == 'document'
				})
			},

			documentsLeft () {
				return this.documentsList.filter(document => {
					return !this.deal.data[document.name]
				}).length
			},

			readyToSubmit () {
				return this.questionsList && this.questionsList.length > 0 && this.isLastQuestion(this.questionsList[this.questionsList.length - 1])
			}
		},

		methods: {
			...mapActions([
				'updateDeal',
				'showModal'
			]),

			submitDeal () {
				this.showModal({
					name: 'SubmitDeal',
					data: {
						dealId: this.deal.id
					}
				})
			},

			isLastQuestion (question) {
				let schemeItem = this.deal.scheme[question.flow][question._id]
				let next = schemeItem.next || schemeItem.options[0]

				if (!next) return false

				return this.questions[next].type == 'end' && !this.questions[next].nextFlow
			},

			setActiveQuestion (index) {
				this.activeQuestion = index
			},

			toggleOutstanding() {
				this.outstadingOnly = !this.outstadingOnly
				mixpanel.track("Outstanding mode toggled", {
					"Outstanding mode": this.outstadingOnly ? "On" : "Off",
					"Triggered by": "User"
				})
			},

			initQestionsList () {
				if (this.deal.questionsStory.length > 0) {
					this.questionsList = this.deal.questionsStory
				} else {
					const initialQuestion = getFirstQuestion({ dealId: this.deal.id, flow: this.initialFlow })
					
					this.questionsList.push(initialQuestion)
				}

				this.setActiveQuestion(this.questionsList.length - 1)
			},

			updateQuestionsList ({ index, id }) {
				let nextQuestion = getNextQuestion({
					dealId: this.deal.id,
					currentQuestionId: id, 
					currentElementIndex: index 
				})

				let shouldBeSaved = false

				window.analytics.track("Questionnaire step viewed", {
					"step_name": nextQuestion.name,
					"step_question": nextQuestion.subtitle,
					"step_type": nextQuestion.type,
					"step_appearance": nextQuestion.appearance,
					"step_position": index+1,
					"deal_id": this.deal.id
				});

				if (index < this.questionsList.length - 1) {
					if (nextQuestion.name !== this.questionsList[index + 1].name) {
						this.questionsList.length = index + 1
						this.questionsList.push(nextQuestion)
						shouldBeSaved = true
					} else {
						this.scrollToLastQuestion()
					}
				} else {
					this.questionsList.push(nextQuestion)
					shouldBeSaved = true
				}

				if (shouldBeSaved) {
					setTimeout(() => {
						this.saveQuestionsList()
					}, 1000)
				}
			},

			async saveQuestionsList () {				
				try {
					await this.updateDeal({
						id: this.deal.id,
						questionsStory: this.questionsList
					})
				} catch(e) {
					console.log(e)
				}
			},

			scrollToLastQuestion () {
				let $question = document.querySelector('#question-'+this.questionsList[this.questionsList.length - 1]._id)
				if ($question) {
					let index = parseInt($question.dataset.index)
					this.setActiveQuestion(index)
					$question.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
				}
			},

			handleDocumentUpdate (questionId) {
				const index = this.questionsList.length - 1

				if (questionId == this.questionsList[index]._id) {
					this.updateQuestionsList({
						index,
						id: questionId
					})
				}
			}
		},

		watch: {
			questionsList: {
				handler () {
					this.$nextTick(() => {
						this.scrollToLastQuestion()
					})
				},

				deep: true
			}
		},

		mounted () {
			this.initQestionsList()
			if (window && window.location.search.includes('outstanding')) {
				this.outstadingOnly = true;
				mixpanel.track("Outstanding mode toggled", {
					"Outstanding mode": "On",
					"Triggered by": "URL"
				})
			}
		}
	}

</script>

<style lang='scss' >
	.questionaire {
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 0 0 0 50px;
		width: 100%;
		max-width: 820px;
		padding-bottom: 28vh;

		&-wrapper {
			display: flex;
			flex-direction: row;
			justify-content: center;
			padding: 35px 30px;
		}

		&__sidebar {
		}

		&__navigation {
			width: 100%;
			text-align: left;
			/* padding-top: 35px; */
			margin-bottom: 60px;
			border-bottom: 1px solid var(--very-light-blue);
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			position: -webkit-sticky;
			position: sticky;
			top: 68px;
			background: var(--pale-grey);
			z-index: 9;
			padding-top: 30px;
			margin-top: -30px;
			&:after {
				left:0;
				top: 100%;
				position: absolute;
				width: 100%;
				margin-top: 1px;
				pointer-events:none;
				height: 67px;
				content:"";
				display:block;	
				background-image: linear-gradient(to top, rgba(252, 252, 254, 0), #f7f8fc);
			}

			.left {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
			}

			.switch-wrap {
				display: flex; 
				align-items: center;
				justify-content: space-between;
				padding-bottom: 9px;
				span {
					font-size: 13px;
					font-weight: 500;
					color: var(--dark-grey-blue);
				}	
				.switch {
					height: 24px;
					width: 42px;
					margin-left: 13px;
					border-radius: 12px;
					background-color: var(--light-grey-blue);
					cursor: pointer;
					transition: background-color 0.3s;
					/* display: flex; */
					.circle {
						width: 16px;
						height: 16px;
						background-color: white;
						display: inline-block;
						border-radius: 50%;
						transform: translateY(2px) translateX(5px);
						pointer-events: none;
						transition: transform 0.15s;
					}
					&.is-active {
						background-color: var(--dull-orange);
						.circle {
							transform: translateY(2px) translateX(21px);
						}
					}  
				}
			}
			.mobile-switch {
				span {
					font-size: 13px;
					font-weight: 500;
					color: var(--orange);
					margin-left: 3px;
					border-bottom: 1px solid;
					display: inline-block;
					line-height: 1.1;	
					vertical-align: middle;
				}
			}

			a {
				font-weight: 500;
				font-size: 13px;
				color: var(--blue-grey);
				display: block;
				position: relative;
				&:hover {
					color: var(--dark-grey-blue);
				}

				&.is-disabled {
					color: var(--powder-blue);
					pointer-events: none;
				}

				&.is-active {
					color: var(--dark-grey-blue);

					&:after {
						position: absolute;
						top: 100%;
						left: 0;
						width: 100%;
						height: 1px;
						display: block;
						content: "";
						background-color: var(--dark-grey-blue);
					}
				}
				.docs-left {
					border-radius: 5px;
					background-color: var(--vermillion);
					font-size: 10px;
					font-weight: 600;
					color: #ffffff;
					content: data(data-docsleft);
					display: inline-block;
					width: 17px;
					text-align: center;
					height:17px;
					line-height: 17px;
					padding-right: 1px; /* alignment fix*/
					margin-left: 3px;
				}
			}
		}
	}
	.deal-header {
		display: flex;
		align-items: center;
	}
	.deal-icon {
		width: 40px;
		height: 40px;
		object-fit: contain;
		border-radius: 12px;
		box-shadow: 0 2px 14px 0 rgba(71, 71, 71, 0.08);
		background-color: #ffffff;
		margin-right: 20px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 40px;
	}
	.outstanding-only {
		.q:not(.is-previous.is-skipped):not(.is-active){
			display: none !important;
		}
	}
	.deal-name {
		font-size: 16px;
		font-weight: 600;
		color: var(--dark-indigo);
		line-height: 1.23;
	}
	.deal-submit {
		width: 100%;
		margin-top: 20px;
	}
	.floating-piece {
		position: -webkit-sticky; /* Safari */
		position: sticky;
		top: 98px;
	}

	@media screen and (max-width: 664px){ 
		.questionaire {
			padding: 0 17px;
			&__navigation {
				margin-bottom: 25px;
				top: 70px;
				z-index: 97;
				padding-top: 12px;
				margin-top: -12px;
				&:after {
					height: 30px;
				}
			}
			&-wrapper {
				padding-top: 25px;
				padding-right: 0;
				padding-left: 0;
			}
		}
		.questionaire__sidebar {
			position: fixed;
			top: 0px;
			flex: none;
			width: 100%;
			left: 0;
			background: white;
			z-index: 99;
			display: none;
		}
		.floating-piece {
			position: static;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: 15px 20px;
			box-shadow: 0 3px 25px 20px rgba(174, 185, 193, 0.18);
			.mobile-left {
				flex-grow: 1;
				& + a {
					flex: 0 0 100px;
				}
			}
			.btn {
				padding: 9px 15px;
				border-radius: 12px;
				width: auto;
				font-size: 12px;
				margin-top:0;
				flex: 0 0 129px;
			}
		}
		.progress-bar-wrap {
			margin-top: 0;
		}
		.progress-bar-legend {
			flex: 0 0 30px;
		}
		.deal-name {
			font-size: 14px;
		}
		.deal-icon {
			margin-right: 15px;
		}
		.deal-header-info {
			width: 100%;
			padding-right: 20px;
		}
		.deal-name {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
		.mobile-left, .deal-header-info {
			min-width: 0;
		}
	}
	@media screen and (max-height: 860px){ 
		.questionaire {
			padding-bottom: 0;
		}
	}
	@media screen and (min-width: 665px) and (max-height: 600px){ 
		.questionaire-wrapper {
			padding-bottom: 0;
		}
	}
</style>