<template>
	<div class="documents">
		<div class="documents-heading">
			<h1>Documents to upload</h1>
			<!-- <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p> -->
		</div>
				
		<div
			v-for="(group, key) in documentGroups"
			:key="`docs-${key}`"
			class="docs-group"
		>
			<div class="docs-group-heading">{{ groupNames[key] }}</div>
			<div
				class="docs-group-list"
			>
				<questionnaire-document
					v-for="doc in group"
					:documentData="doc"
					:deal="deal"
					:key="`doc-${doc._id}`"
					@document-updated="$emit('document-updated', $event)"
				/>
			</div>
		</div>
	</div>
</template>



<script>

	import QuestionnaireDocument from '@/components/QuestionnaireDocument'
	
	export default {
		name: 'QuestionnaireDocuments',

		props: {
			documents: {
				type: Array
			},

			deal: {
				type: Object,
				required: true
			}
		},

		components: {
			QuestionnaireDocument
		},

		computed: {
			documentGroups () {
				const groups = {}
				
				this.documents.forEach(element => {
					if (!groups[element.flow]) groups[element.flow] = []
					groups[element.flow].push(element)	
				})

				return groups
			}
		},

		data () {
			return {
				groupNames: {
					'property-flow': 'Property-related documents',
					'borrower-flow': 'Borrower-related documents',
					'financials-flow': 'Financials-related documents'
				}
			}
		}
	}
</script>



<style lang="scss" scoped>
	.documents {
		width: 100%;
	}
	h1 {
		font-size: 35px;
		font-weight: 600;
		color: var(--dark-grey-blue);
		&:first-child{
			margin-top:0;
		}
	}
	h1+p {
		font-size: 15px;
		line-height: 1.73;
		color: var(--dark-indigo);
	}
	.docs-group {
		width: 100%;
		&-heading {
			font-size: 15px;
			line-height: 1.73;
			color: var(--dark-grey-blue);
			margin-top: 40px;
			margin-bottom: 30px;
		}
		&-list {
			margin: 0 -7.5px;
		}
	}
	@media screen and (max-width: 664px) {
		h1 {
			font-size: 24px;
			font-weight: 600;
			line-height: 1.17;
		}
		h1 + p {
			font-size: 14px;
			line-height: 1.5;
		}
		.docs-group-heading {
			margin-bottom: 25px;
		}
		.docs-group-list {
			margin: 0;
		}
	}
</style>