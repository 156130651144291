import { getFirstQuestion, getNextQuestion, isLastQuestion } from '@/helpers/questionnaire.js'

import getDealName from '@/helpers/getDealName.js'

import multifamily from '@/assets/icons/types/multifamily-small.svg'
import office from '@/assets/icons/types/office-small.svg'
import retail from '@/assets/icons/types/retail-small.svg'
import industrial from '@/assets/icons/types/industrial-small.svg'
import hospitality from '@/assets/icons/types/hospitality-small.svg'
import residential from '@/assets/icons/types/1-4-unit-residential-small.svg'

export default {
	computed: {
		name () {
			return getDealName(this.deal)
		},

		icon () {
			const map = {
				'Multifamily 5+ Units': multifamily,
				'Office': office,
				'Retail': retail,
				'Industrial': industrial,
				'Hospitality': hospitality,
				'1-4 Unit Residential': residential
			}

			return this.deal.data && this.deal.data['Property type'] ? map[this.deal.data['Property type']] : multifamily
		},

		estimatedQuestionsList () {
			let estimatedQuestionsList = []

			if (this.deal.questionsStory.length > 0) {
				estimatedQuestionsList = JSON.parse(JSON.stringify(this.deal.questionsStory))
			} else {
				estimatedQuestionsList.push(getFirstQuestion({
					dealId: this.deal.id,
					flow: this.deal.type !== 'Business' ? 'property-flow' : 'sba-flow'
				}))
			}

			while (!isLastQuestion({
				dealId: this.deal.id,
				question: estimatedQuestionsList[estimatedQuestionsList.length - 1]
			})) {
				estimatedQuestionsList.push(
					getNextQuestion({
						dealId: this.deal.id,
						currentQuestionId: estimatedQuestionsList[estimatedQuestionsList.length - 1]['_id']
					})
				)
			}

			return estimatedQuestionsList
		},

		questionsAnswered () {
			return this.deal.questionsStory.length > 0 ? this.deal.questionsStory.filter(question => {
				return question ? this.deal.data[question.name] : false
			}) : []
		},

		estimatedDocumentsList () {
			return this.estimatedQuestionsList.filter(question => question.type == 'document')
		},

		documentsUploaded () {
			return this.questionsAnswered.filter(question => question.type == 'document' && !!this.deal.data[question.name]).length
		},

		progress () {
			return (this.questionsAnswered.length / this.estimatedQuestionsList.length * 100).toFixed(2)
		},

		roundedProgress () {
			return parseInt(this.progress)
		},

		estimatedProgress () {
			return ((this.questionsAnswered.length + 1) / this.estimatedQuestionsList.length * 100).toFixed(2)
		},

		roundedEstimatedProgress () {
			return Math.ceil(this.estimatedProgress)
		},

		readyToSubmit () {
			return isLastQuestion({
				dealId: this.deal.id,
				question: this.deal.questionsStory[this.deal.questionsStory.length - 1]
			})
		}
	}
}