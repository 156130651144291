<template>
  <form novalidate @submit.prevent="handleFormSubmit">
    <h3>Profile picture</h3>
    
    <div class="avatar-block">
      <div class="avatar" :style="{ 'background-image': `url(${customer.image})` }">
        <div v-if="!customer.image"><img src="@/assets/icons/avatar-placeholder.svg" alt="Upload your piture here"></div>
      </div>

      <div class="file-wrap">
        <input type="file" @change="handleFileChange">
        <div class="file-upload-hint" v-if="!file">
          <div>
            <!-- Drag document here or<br>use upload button on the right -->
          </div>  
          <button class="btn btn__small btn__secondary">Upload</button>
        </div>
        <div class="file-upload-hint" v-else>{{ file.name }}</div>
      </div>
    </div>

    <div class="buttons-wrap" v-if="fileIsValid">
      <button class="btn  btn__secondary" type="cancel" :disabled="isLoading" @click="handleCancel">Cancel</button>
      <button class="btn  btn__primary" type="submit" :disabled="isLoading">Save changes</button>
    </div>
  </form>
</template>



<script>

  import { mapActions, mapGetters } from 'vuex'
  
  export default {
    name: 'ProfileAvatar',

    data () {
      return {
        value: '',
        file: null,
        beenChanged: false,
        isLoading: false
      }
    },

    computed: {
      ...mapGetters([
        'customer'
      ]),

      fileIsValid () {
        const regex = new RegExp("(.*?).(jpg|jpeg|png)$")

        return this.file && regex.test(this.file.name)
      }
    },

    methods: {
      ...mapActions([
        'uploadFile',
        'updateCustomer'
      ]),

      async handleFormSubmit () {
        try {
          if (!this.isLoading && this.beenChanged && this.fileIsValid) {
            this.isLoading = true
            const formData = new FormData()
            
            formData.append('file', this.file)
            formData.append('name', 'avatar')
            
            const path = await this.uploadFile(formData)

            await this.updateCustomer({
              image: path
            })

            this.isLoading = false
            this.file = null
          }
        } catch (e) {
          console.log(e)
          this.isLoading = false
        }
      },

      handleCancel () {
        this.file = null
      },

      handleFileChange (e) {
        this.beenChanged = true
        this.file = (e.target.files[0]) ? e.target.files[0] : null
      }
    }
  }

</script>



<style lang="scss" scoped>
  .buttons-wrap {
    padding-top: 45px;
  }

  .avatar {
    width: 128px;
    height: 128px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 16px;
    flex: 0 0 128px;
    margin-right: 50px;
    background-color: var(--pale-grey);
    div {
      widtH: 100%;
      height: 100%;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 50px;
    }
  }
  .avatar-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:last-child {
      padding-bottom: 60px;
    }
  }
  h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.22;
    color: var(--dark-grey-blue);
    margin-bottom: 35px;
  }
  .btn {
    display: block;
    margin-left: auto;
  }
  input[type=file] {
    /* height: 128px; */
  }
  .file-wrap {
    height: 128px;
    border-radius: 16px;
    border: dashed 1px var(--light-grey-blue);
    box-sizing: border-box;
    width: 100%;
    position: relative;
    font-size: 13px;
    font-weight: 500;
    color: var(--dark-indigo);
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      position: absolute;
      top: 0;
      left:0;
      right:0;
      bottom: 0;
      opacity: 0;
    }
  }
  @media screen and (max-width: 664px){
    .file-wrap {
      /* height: auto; */
      flex-direction: column;
    }
    .avatar-block {
      flex-direction: column;
      padding-bottom: 20px !important;
    }
    .avatar {
      margin: 0 0 30px 0;
    }
    h3 {
      text-align: center;
      font-size: 16px;
    }
  }
</style>
