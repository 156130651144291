export default {
  category: 'socialMedia',

  fields: {
    facebook: {
      model: 'facebook',
      type: 'text',
      label: 'Facebook',
      placeholder: 'facebook.com',
      validations: [],
      editable: true
    },

    twitter: {
      model: 'twitter',
      type: 'text',
      label: 'Twitter',
      placeholder: 'twitter.com',
      validations: [],
      editable: true
    },

    linkedin: {
      model: 'linkedin',
      type: 'text',
      label: 'Linkedin',
      placeholder: 'linkedin.com',
      validations: [],
      editable: true
    }
  }
}