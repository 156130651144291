<template>
	<div>
		<!-- Waiting for authorization... -->
	</div>
</template>



<script>
	
	import { mapActions, mapGetters } from 'vuex'

	export default {
		name: 'PageOneTimeAuth',

		data () {
			return {
				redirect: ''
			}
		},

		computed: {
			...mapGetters([
				'isAuth',
				'customer'
			])
		},

		methods: {
			...mapActions([
				'notify',
				'authorizeCustomerByOneTimeCode',
				'showModal'
			])
		},

		watch: {
			isAuth () {
				if (this.isAuth) {
					if (this.redirect) this.$router.push(this.redirect)
					else this.$router.push('/')
				}
			}
		},

		async mounted () {
			this.redirect = this.$route.query?.redirect

			if (this.$route.query?.token) {
				try {
					const result = await this.authorizeCustomerByOneTimeCode(this.$route.query?.token)

					if (!result) {
						this.notify({
							message: 'Your authorization link may be expired',
							type: 'error'
						})
						this.$router.push('/sign-in#login')
					}
				} catch(e) {
					this.notify({
						message: 'Your authorization link may be expired',
						type: 'error'
					})

					this.$router.push('/sign-in#login')
				}
			}
		}
	}

</script>