<template>
	
	<div class="modal-text">
		<h2>Is this a business loan or an investment property loan?</h2>
		<p>If the loan is secured by (non residential) real estate that you are using more than 51% of to operate your business, please choose business.</p>
	</div>

	<div class="modal-btns">
		<button class="white" type="button" @click="createDeal('Investment Property')" :disabled="isLoading">Investment Property</button>
		<button class="white" type="button" @click="createDeal('Business')" :disabled="isLoading">Business</button>
	</div>
	
</template>



<script>

	import { mapActions, mapGetters } from 'vuex'

	import mixpanel from '@/helpers/mixpanel.js'
	
	export default {
		name: 'ModalNewDeal',

		data () {
			return {
				isLoading: false
			}
		},

		emits: ['closed'],

		props: {
			modalData: {
				type: Object
			}
		},

		computed: {
			...mapGetters([
				'deals'
			])
		},

		methods: {
			...mapActions([
				'createDeal',
				'notify'
			]),

			closeModal () {
				this.$emit('closed')
			},

			async submitDeal (type) {
				if (!this.isLoading) {
					try {
						this.isLoading = true
						
						await this.createDeal(type)
					} catch(e) {
						this.notify({
							type: 'error',
							message: e.message || e.error
						})
					}
				}
			},
		},

		watch: {
			deals () {
				this.isLoading = false

				this.notify({
					message: 'Deal was successfully created'
				})

				mixpanel.track("Manual deal creation", {
					"Deal ID": this.deals[this.deals.length-1]._id,
				})

				window._hsq.push(["trackEvent", { 
					id: "Portal – New deal created (manually)"
				}]);

				this.$router.push(`/deal/${this.deals[this.deals.length-1]._id}`)
				this.closeModal()
			}
		}
	}
</script>



<style lang="scss">
	
	.modal {
		width: 400px;
		background: white;
		padding: 40px;
		box-sizing: border-box;

		h2 {
			line-height: 1.2;
		}

		.modal-btns {
			text-align: left !important;
			margin-bottom: 15px;

			button.white {
				padding: 26px 30px;
				border-radius: 16px;
				margin-right: 15px;
				margin-bottom: 15px;
				display: inline-block;
				position: relative;
				font-size: 15px;
				font-weight: 500;
				color: var(--dark-indigo);
				cursor: pointer;
				user-select: none;
				display: inline-flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				background: white;
				border: none;
				outline: none;
				font-weight: 500;
				font-family: inherit;

				&:disabled {
					opacity: 0.6;
				}

				&:after {
					content: "";
					position: absolute;
					left:0;
					right:0;
					bottom:0;
					top:0;
					z-index: 1;
					border-radius: 16px;
					box-shadow: 0 5px 33px -11px rgba(51, 77, 97, 0.21);
					transition: box-shadow 0.2s ease-out;
				}

				&:hover{
					&:after {
						box-shadow: 0 18px 70px -18px rgba(236, 130, 14, 0.5);
					}
				}
			}
		}
	}

</style>