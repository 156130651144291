<template>
	<div class="intro-page">
		<div class="image"><img src="@/assets/logo-full-dark.svg" alt="" class="logo"><img class="bg" src="@/assets/intro-bg.jpg" alt=""></div>
		<div class="content">
			<div class="cta" v-if="activeTab == 'intro'">
				<div class="logo"><img src="@/assets/logo-small-dark.svg" alt="" class="logo"></div>
				<h1 class="mt-6">Get commercial property financing <span class="color-accent">your&nbsp;bank can't compete</span> with.</h1>
				<div class="cta-text">We're making commercial property financing easier.<br>Click the button below to get started.</div>
				<div class="btns-wrap">
					<router-link to="/sign-up" class="btn">Get started</router-link>
				</div>
			</div>
			<div class="login" v-if="activeTab == 'login'">
				<div class="cta">
					<div class="logo"><img src="@/assets/logo-full-dark.svg" alt="" class="logo"></div>
					<form @submit.prevent="handleFormSubmit" v-if="!success">
						<h1 class="mt-6">Welcome back to Janover Ventures!</h1>
						<div class="cta-text">To get a login link please type your email below.</div>
						<input autofocus type="email" name="email" v-model="email" placeholder="john@creprops.com">
						<div>
							<button class="btn" :disabled="!email" type="submit">Get a link</button>
						</div>
					</form>

					<div v-else>
						<h1>Please check your email</h1>
						<div class="cta-text">You should get a login link shortly.</div>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="copyright">Copyright 2022 © Janover Inc.</div>
				<div class="alternative-cta" v-if="activeTab !== 'login'">
					<span>Login to continue your submission</span>
					<div class="btn btn__secondary" @click.prevent="activeTab = 'login'">Log in</div>
				</div>
				<div class="alternative-cta" v-if="activeTab == 'login'">
					<span>Don't have an account?</span>
					<router-link to="/sign-up" class="btn">Start Submission</router-link>
				</div>
			</div>
		</div>
	</div>
	
</template>



<script>

	import { mapActions } from 'vuex'
	
	export default {
		name: 'PageLogin',

		data () {
			return {
				email: '',
				success: false,
				activeTab: 'intro'
			}
		},

		methods: {
			...mapActions([
				'loginByEmail',
				'notify'
			]),

			async handleFormSubmit() {
				try {
					const response = await this.loginByEmail(this.email)

					this.success = !!response
				} catch(e) {
					console.log(e)
					this.notify({
						type: 'error',
						message: e.error || e
					})
				}
			}
		},

		created () {
			if (this.$route.hash == '#login' || !this.$route.query.intro) this.activeTab = 'login'
		}
	}

</script>



<style lang="scss" scoped>
	.intro-page {
		display: flex;
		min-height: 100vh;
		
		.image {
			flex: 0 0 30%;
			position: fixed;
			top: 0;
			bottom:0;
			width: 30%;
			/* height: 100vh; */
			.logo {
				position: absolute;
				margin: 24px 30px;
			}
			.bg {
				object-fit: cover;
				width: 100%;
				height: 100%;
				object-position: center;
			}
		}
		.content {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			width: 100%;
			padding: 25vh 10% 0%;
			position: relative;
			margin-left: 30%;
			flex-direction: column;

			@media screen and (min-width: 1000px) and (min-height: 640px) {
				padding: calc((100vh - 564px) / 2) 10% 0%;
			}
		}
		h1 {
			/* font-size: 52px; */
			font-size: 49px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.06;
			letter-spacing: normal;
			color: var(--dark-grey-blue);
			margin-bottom: 30px;
		}
		.cta {
			max-width: 640px;
			.logo img {
				height: 47px;
			}
		}
		.cta-text {
			font-size: 17px;
			line-height: 1.71;
			color: var(--dark-indigo);
		}
		.btns-wrap {
			margin-top: 45px;
			span {
				margin: 0 20px;
				color: var(--blue-grey)
			}
		}
		.footer {
			justify-self: flex-end;
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			padding: 20px 0 30px;
			border-top: 1px solid var(--powder-blue);
			.alternative-cta {
				span {
					font-size: 13px;
					line-height: 1.62;
					color: var(--dark-indigo);
					margin-right: 30px;
				}
			}
		}
		.login {
			input {
				margin: 40px 0;
				max-width: 300px;
				width: 100%;
			}
		}
	}
	@media screen and (max-width: 1000px) {
		.intro-page {
			.image {
				display: none;
			}
			.content {
				margin-left: 0;
			}
		}
	}
	@media screen and (max-width: 664px) {
		.intro-page {
			width: 100%;
			.content {
				padding: 80px 35px 30px;
			}
			h1 {
				font-size: 32px;
				font-weight: 600;
				line-height: 1.09;
				margin-bottom: 20px;
			}
			.cta-text {
				font-size: 15px;
			}
			.btns-wrap {
				margin-top: 40px;
				span {
					display: block;
					margin: 20px 30px;
					font-size: 15px;
				}
			}
			.footer {
				text-align: center;
				width: 100%;
				left: 0;
				box-sizing: border-box;
				flex-direction: column-reverse;
				border-top: 0;
				padding-bottom: 0;
				.alternative-cta {
					flex-direction: row;
					display: flex;
					align-items: center;
					span {
						text-align: left;
					}
					.btn {
						flex: 0 0 60px;
					}
				}
				.copyright {
					margin-top: 30px;
					padding-top: 15px;
					border-top: 1px solid var(--powder-blue);
					width: 100%;
				}
			}
			.cta {
				max-width: 100%;
			}
			.login {
				input {
					margin: 40px 0;
					max-width: 200px;
					padding: 15px 20px;
					font-size: 14px;
				}
			}
		}
	}
</style>