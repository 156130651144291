<template>
	<div class="intro-page">
		<router-link to="/" class="image">
			<img src="@/assets/logo-full-dark.svg" alt="" class="logo">
			<img class="bg" src="@/assets/intro-bg.jpg" alt="">
		</router-link>
		<div class="content signup">
			<sign-up-form />
			<div class="footer">
				<div class="copyright">Copyright 2022 © Janover Inc.</div>
			</div>
		</div>
	</div>
	
</template>



<script>

	import { mapGetters, mapActions } from 'vuex'
	import SignUpForm from '@/components/SignUpForm'
	
	export default {
		name: 'PageSignUp',

		components: {
			SignUpForm
		},

		computed: {
			...mapGetters([
				'isAuth',
				'customer'
			])
		},

		methods: {
			...mapActions([
				'showModal'
			])
		},

		watch: {
			isAuth () {
				if (this.isAuth) {
					this.$router.push(`/deal/${this.customer.deals[0]}`)
					this.showModal({
						name: 'Welcome'
					})
				}
			}
		}
	}

</script>



<style lang="scss" scoped>
	.intro-page {
		display: flex;
		min-height: 100vh;
		
		.image {
			flex: 0 0 30%;
			position: fixed;
			top: 0;
			bottom:0;
			width: 30%;
			/* height: 100vh; */
			.logo {
				position: absolute;
				margin: 24px 30px;
			}
			.bg {
				object-fit: cover;
				width: 100%;
				height: 100%;
				object-position: center;
			}
		}
		.content {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			width: 100%;
			padding: 10%;
			position: relative;
			margin-left: 30%;

			&.signup {
				padding: 40px 38px 80px 50px;
				align-items: flex-start;
			}
		}
		h1 {
			font-size: 52px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.06;
			letter-spacing: normal;
			color: var(--dark-grey-blue);
			margin-bottom: 30px;
		}
		.cta {
			max-width: 640px;
			.logo img {
				width: 47px;
			}
		}
		.cta-text {
			font-size: 17px;
			line-height: 1.71;
			color: var(--dark-indigo);
		}
		.btns-wrap {
			margin-top: 45px;
			span {
				margin: 0 20px;
				color: var(--blue-grey)
			}
		}
		.footer {
			position: absolute;
			bottom: 0px;
			left: 0px;
			padding: 30px 50px;
		}
		.login {
			input {
				margin: 40px 0;
				max-width: 300px;
				width: 100%;
			}
		}
	}

	@media screen and (max-width: 1000px) {
		.intro-page {
			.image {
				display: none;
			}
			.content {
				margin-left: 0;
			}
		}
	}
	@media screen and (max-width: 664px) {
		.intro-page {
			.content, .content.signup {
				padding: 20px 17px 90px;
			}
			h1 {
				font-size: 35px;
			}
			.cta-text {
				font-size: 15px;
			}
			.btns-wrap {
				span {
					display: block;
					margin: 20px 30px;
					font-size: 15px;
				}
			}
			.footer {
				text-align: center;
				width: 100%;
				left: 0;
				box-sizing: border-box;
			}
			.cta {
				max-width: 100%;
			}
			.login {
				input {
					margin: 40px 0;
					max-width: 200px;
					padding: 15px 20px;
					font-size: 14px;
				}
			}
		}
	}
</style>