export default {
  category: 'homeAddress',

  fields: {
    street: {
      model: 'street',
      type: 'text',
      label: 'Street',
      placeholder: '5th Avenue',
      validations: [],
      editable: true
    },

    building: {
      model: 'building',
      type: 'text',
      label: 'Building',
      placeholder: 'Suite G-1111',
      validations: [],
      editable: true
    },

    zip: {
      model: 'zip',
      type: 'text',
      label: 'ZIP',
      placeholder: '12345',
      validations: [],
      editable: true
    },

    city: {
      model: 'city',
      type: 'text',
      label: 'City, State',
      placeholder: 'New York, NY',
      validations: [],
      editable: true
    }
  }
}