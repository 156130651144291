import store from '@/store'

export const getFirstQuestion = ({ dealId, flow }) => {
	const deal = store.getters['deals'].filter(deal => deal.id == dealId)[0]
	const { scheme } = deal

	let startId = -1 

	Object.values(scheme[flow]).forEach((item, index) => {
		if (item.type == 'start') startId = Object.keys(scheme[flow])[index]
	})

	let firstQuestion = getNextQuestion({
		dealId,
		currentQuestionId: startId
	})

	return firstQuestion
}

export const getNextQuestion = (payload) => {
	const { dealId, currentQuestionId, currentElementIndex = -1 } = payload

	const questions = store.getters['questions']
	const deal = store.getters['deals'].filter(deal => deal.id == dealId)[0]
	
	const { scheme } = deal
	
	const questionsList = (deal.questionsStory && deal.questionsStory.length) ? deal.questionsStory : []

	if (currentElementIndex > -1) {
		questionsList.length = currentElementIndex + 1
	}

	let previousQuestion = questions[currentQuestionId]

	let { flow } = previousQuestion
	let { next } = scheme[flow][currentQuestionId]

	if (previousQuestion.options.length > 0) {
		if (deal.data && deal.data[previousQuestion.name]) {
			let questionValue = deal.data[previousQuestion.name]
			next = scheme[flow][currentQuestionId].options[previousQuestion.options.indexOf(questionValue)]
		}

		if (!next) next = scheme[flow][currentQuestionId].options[0]
	}

	let nextQuestion = questions[next]

	let isDuplicate = questionsList.length && questionsList.filter(question => {
		return question.name == nextQuestion.name
	}).length > 0

	if (isDuplicate) {
		nextQuestion = getNextQuestion({
			dealId,
			currentQuestionId: next
		})
	}

	if (nextQuestion.type == 'end' && nextQuestion.nextFlow) {
		nextQuestion = getFirstQuestion({
			flow: nextQuestion.nextFlow,
			dealId
		})
	}

	return nextQuestion
}

export const isLastQuestion = ({ dealId, question }) => {
	const questions = store.getters['questions']
	const deal = store.getters['deals'].filter(deal => deal._id == dealId)[0]
	const { scheme } = deal

	if (!question) return false
	
	let next = scheme[question.flow][question._id].next

	if (!next) return false

	return questions[next].type == 'end' && !questions[next].nextFlow
}