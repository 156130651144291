<template>
	<div class="message-page">
		<router-link to="/" class="image">
			<img src="@/assets/logo-full-dark.svg" alt="" class="logo">
			<img class="bg" src="@/assets/intro-bg.jpg" alt="">
		</router-link>
		
		<div class="content">
			<div class="cta">
				<div class="logo"><img src="@/assets/logo-full-dark.svg" alt="" class="logo"></div>
				<div class="message-box" v-html="message"></div>
			</div>
			<div class="footer">
				<div class="copyright">Copyright 2022 © Janover Inc.</div>
			</div>
		</div>
	</div>
	
</template>



<script>
	
	export default {
		name: 'PageMessage',

		data () {
			return {
				message: '<h1>Something went wrong.</h1>'
			}
		},

		created () {
			if (this.$route.params?.message) this.message = this.$route.params.message
		}
	}

</script>



<style lang="scss">
	.message-page {
		display: flex;
		min-height: 100vh;
		
		.image {
			flex: 0 0 30%;
			position: fixed;
			top: 0;
			bottom:0;
			width: 30%;
			/* height: 100vh; */
			.logo {
				position: absolute;
				margin: 24px 30px;
			}
			.bg {
				object-fit: cover;
				width: 100%;
				height: 100%;
				object-position: center;
			}
		}
		.content {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			width: 100%;
			padding: 25vh 10% 0%;
			position: relative;
			margin-left: 30%;
			flex-direction: column;
		}
		h1 {
			/* font-size: 52px; */
			font-size: 49px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.06;
			letter-spacing: normal;
			color: var(--dark-grey-blue);
			margin-bottom: 30px;
		}
		.cta {
			max-width: 640px;
			.logo img {
				height: 47px;
			}
		}
		.cta-text {
			font-size: 17px;
			line-height: 1.71;
			color: var(--dark-indigo);
		}
		.btns-wrap {
			margin-top: 45px;
			span {
				margin: 0 20px;
				color: var(--blue-grey)
			}
		}
		.footer {
			justify-self: flex-end;
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			padding: 20px 0 30px;
			border-top: 1px solid var(--powder-blue);
			.alternative-cta {
				span {
					font-size: 13px;
					line-height: 1.62;
					color: var(--dark-indigo);
					margin-right: 30px;
				}
			}
		}
		.login {
			input {
				margin: 40px 0;
				max-width: 300px;
				width: 100%;
			}
		}
	}
	@media screen and (max-width: 1000px) {
		.message-page {
			.image {
				display: none;
			}
			.content {
				margin-left: 0;
			}
		}
	}
	@media screen and (max-width: 664px) {
		.message-page {
			width: 100%;
			.content {
				padding: 80px 35px 30px;
			}
			h1 {
				font-size: 32px;
				font-weight: 600;
				line-height: 1.09;
				margin-bottom: 20px;
			}
			.cta-text {
				font-size: 15px;
			}
			.btns-wrap {
				margin-top: 40px;
				span {
					display: block;
					margin: 20px 30px;
					font-size: 15px;
				}
			}
			.footer {
				text-align: center;
				width: 100%;
				left: 0;
				box-sizing: border-box;
				flex-direction: column-reverse;
				border-top: 0;
				padding-bottom: 0;
				.alternative-cta {
					flex-direction: row;
					display: flex;
					align-items: center;
					span {
						text-align: left;
					}
					.btn {
						flex: 0 0 60px;
					}
				}
				.copyright {
					margin-top: 30px;
					padding-top: 15px;
					border-top: 1px solid var(--powder-blue);
					width: 100%;
				}
			}
			.cta {
				max-width: 100%;
			}
			.login {
				input {
					margin: 40px 0;
					max-width: 200px;
					padding: 15px 20px;
					font-size: 14px;
				}
			}
		}
	}
</style>