import multifamily from '@/assets/icons/types/multifamily-small.svg'
import office from '@/assets/icons/types/office-small.svg'
import retail from '@/assets/icons/types/retail-small.svg'
import industrial from '@/assets/icons/types/industrial-small.svg'
import hospitality from '@/assets/icons/types/hospitality-small.svg'
import residential from '@/assets/icons/types/1-4-unit-residential-small.svg'
import storage from '@/assets/icons/types/self-storage.svg'
import mobile from '@/assets/icons/types/mobile-home-park.svg'
import construction from '@/assets/icons/purposes/construction.svg'
import other from '@/assets/icons/purposes/other.svg'
import purchase from '@/assets/icons/purposes/purchase.svg'
import refinance from '@/assets/icons/purposes/refinance.svg'
import business from '@/assets/icons/types/business.svg'

export default function (name) {
	const map = {
		'Multifamily 5+ Units': multifamily,
		'Office': office,
		'Retail': retail,
		'Industrial': industrial,
		'Hospitality': hospitality,
		'1-4 Unit Residential': residential,
		'Self Storage': storage,
		'Mobile Home Park': mobile,
		'Purchase': purchase, 
		'Refinance': refinance,
		'Construction': construction,
		'Other': other,
		'Investment Property': multifamily,
		'Business' : business
	}

	return name ? map[name] : multifamily
}