<template>
	<div class="signup-steps">
		<div v-if="error">
			<h1>Account with provided email was already registered</h1>
			<div class="cta-text">Check your email for more details.</div>
		</div>

		<template v-else>
			<sign-up-form-step
				:step="initialStep"
				:index="0"
				:displayedIndex="1"
				:isLastStep="false"
				:isCurrent="currentStep == -1"
				@nextStep="handleInitialStep"
			/>

			<template v-if="steps">
				<template v-for="(step, index) in steps">
					<sign-up-form-step 
						v-if="index <= currentStep"
						:step="step"
						:index="index"
						:displayedIndex="index+2"
						:key="`signup-step-${index}`"
						:isLastStep="index == steps.length - 1"
						:isCurrent="index == currentStep"
						:isLoading="isLoading"
						@nextStep="handleNextStep"
					/>
				</template>
			</template>
		</template>
	</div>
</template>



<script>

	import { mapActions } from 'vuex'
	import SignUpFormStep from '@/components/SignUpFormStep'
	import mixpanel from '@/helpers/mixpanel.js'
	
	export default {
		name: 'SignUpForm',

		data () {
			return {
				initialStep: {
					name: 'Loan category',
					type: 'selector',
					title: 'Is this a business loan or an investment property loan?',
					hint: 'If the loan is secured by (non residential) real estate that you are using more than 51% of to operate your business, please choose business.',
					options: ['Investment Property', 'Business']
				},

				config: {
					"Investment Property": [
						{
							name: 'Property type',
							type: 'selector',
							title: 'What type of property are you inquiring about?',
							hint: 'If the property is a mix of multifamily and other uses such as office or retail, please select "Multifamily" as your property type.',
							options: [ "Multifamily 5+ Units", "Office", "Retail", "Industrial", "Hospitality", "1-4 Unit Residential", "Self Storage", "Mobile Home Park" ]
						},
						{
							name: 'Loan purpose',
							type: 'selector',
							title: 'What is the purpose of this loan?',
							options: ["Purchase", "Refinance", "Construction"]
						},
						{
							name: 'Loan amount',
							title: 'What is the requested loan amount?',
							hint: "If you aren't entirely sure, please make your best educated guess."
						},
						{
							type: 'form',
							title: 'Contact Details',
							name: 'Contact Details',
							hint: 'Please share your contact information with us so we can provide financing options and a quote. We respect your privacy.',
							footer: 'By checking this box, I confirm that I am an authorized representative of Borrower and agree on Borrower’s behalf that Janover Inc. dba Janover may share Borrower’s information with various lenders in an effort to market the loan and obtain competitive offers. In the event that Borrower contracts to obtain a loan from a lender introduced by Janover, Borrower understands and agrees to pay a reasonable fee to be agreed upon with Janover of the loan amount at closing unless Janover waives or negotiates a different deal. For a period of twenty four (24) months from an introduction to a particular lender, Borrower and its affiliates agree not circumvent this agreement by entering into a separate agreement with such lender and not providing notice of same to Janover. Nothing herein supersedes requirements set forth in the associated engagement agreement for financing. Borrower also agrees to Janover’s <a target="_blank" href="https://www.janover.ventures/privacy">Privacy Policy</a> and <a target="_blank" href="https://www.janover.ventures/terms-of-use">Terms of Use</a> and to receive communications to the contact information shared, including e-mails, SMS/text, autodialed/prerecorded calls for marketing purposes by Janover and its partners. Consent to such contact is not required for purchase and Borrower can opt-out anytime.',
							fields: [
								{
									name: 'firstName',
									label: 'First name',
									placeholder: 'John',
									type: 'text',
									validations: ['required', 'isNameString']
								},
								{
									name: 'lastName',
									label: 'Last name',
									placeholder: 'Appleseed',
									type: 'text',
									validations: ['required', 'isNameString']
								},
								{
									name: 'phone',
									label: 'Phone Number',
									placeholder: '(123) 456-7890',
									mask: '(###) ###-####',
									validations: ['required', 'isPhone']
								},
								{
									name: 'email',
									label: 'Email Address',
									placeholder: 'me@company.com',
									validations: ['required', 'isEmail']
								}
							]
						}
					],
					"Business": [
						{
							name: 'Loan amount',
							title: 'What is the approximate loan amount?',
							hint: 'If you are unsure, please make your best educated guess.',
							placeholder: '$1,000,000',
						},
						{
							name: 'Credit score',
							title: 'How is your credit?',
							hint: 'What is your estimated credit score? A guess is just fine.',
							options: ['499 or below', '500 - 599', '600 - 649', '650 - 679', '680 - 719', '720 or above'],
							type: 'selector'
						},
						{
							name: 'Primary Loan Purpose',
							title: 'Please select the primary intended use of funds.',
							hint: 'Please select the PRIMARY business purpose that you intend to use the loan for.',
							options: ['Expand an Exisiting Business', 'Working Capital', 'Fund Payroll', 'Purchase a Business', 'Purchase a Franchise', 'Purchase Equipment', 'Purchase or Refinance Real Estate', 'Buy Out a Partner', 'Start a Business', 'Finance Accounts Receivable', 'Construction Financing', 'eCommerce Inventory'],
							type: 'selector'
						},
						{
							name: 'Time Incorporated',
							title: 'How long has your business been incorporated?',
							type: 'selector',
							options: ['It’s not incorporated', 'Less than 1 year', '1 – 2 years', '2+ years']
						},
						{
							type: 'form',
							title: 'Contact Details',
							name: 'Contact Details',
							hint: 'Please share your contact information with us so we can provide financing options and a quote. We respect your privacy.',
							footer: 'By checking this box, I confirm that I am an authorized representative of Borrower and agree on Borrower’s behalf that Janover Inc. dba Janover may share Borrower’s information with various lenders in an effort to market the loan and obtain competitive offers. In the event that Borrower contracts to obtain a loan from a lender introduced by Janover, Borrower understands and agrees to pay a reasonable fee to be agreed upon with Janover of the loan amount at closing unless Janover waives or negotiates a different deal. For a period of twenty four (24) months from an introduction to a particular lender, Borrower and its affiliates agree not circumvent this agreement by entering into a separate agreement with such lender and not providing notice of same to Janover. Nothing herein supersedes requirements set forth in the associated engagement agreement for financing. Borrower also agrees to Janover’s <a target="_blank" href="https://www.janover.ventures/privacy">Privacy Policy</a> and <a target="_blank" href="https://www.janover.ventures/terms-of-use">Terms of Use</a> and to receive communications to the contact information shared, including e-mails, SMS/text, autodialed/prerecorded calls for marketing purposes by Janover and its partners. Consent to such contact is not required for purchase and Borrower can opt-out anytime.',
							fields: [
								{
									name: 'firstName',
									label: 'First name',
									placeholder: 'John',
									type: 'text',
									validations: ['required', 'isNameString']
								},
								{
									name: 'lastName',
									label: 'Last name',
									placeholder: 'Appleseed',
									type: 'text',
									validations: ['required', 'isNameString']
								},
								{
									name: 'phone',
									label: 'Phone Number',
									placeholder: '(123) 456-7890',
									mask: '(###) ###-####',
									validations: ['required', 'isPhone']
								},
								{
									name: 'email',
									label: 'Email Address',
									placeholder: 'me@company.com',
									validations: ['required', 'isEmail']
								}
							]
						}
					]
				},

				dealType: '',

				currentStep: -1,
				steps: null,

				collectedData: {},

				error: false,
				isLoading: false
			}
		},

		components: {
			SignUpFormStep
		},

		methods: {
			...mapActions([
				'createCustomer',
				'notify'
			]),

			handleInitialStep ({ value }) {
				this.steps = this.config[value]
				this.collectedData.type = value
				this.currentStep = 0
			},

			async handleNextStep ({ index, name, value, fields }) {
				if (fields) {
					Object.assign(this.collectedData, fields)
				}

				mixpanel.track("Sign up form – Step completed", {
					"Field name": name,
					"Answer": value
				})
				window.analytics.track("Sign up form – Step completed", {
					"Field name": name,
					"Answer": value
				})

				if (name && value) this.collectedData[name] = value

				if (index < this.steps.length - 1) this.currentStep = index + 1
				
				else {
					try {
						if (!this.isLoading) {
							this.isLoading = true

							const response = await this.createCustomer(this.collectedData);

							this.error = !response
							this.isLoading = false

							if (this.error) {
								this.notify({
									type: 'error',
									message: 'Account with provided email was already registered'
								})
							}
						}
					} catch(e) {
						this.isLoading = false
						this.notify({
							type: 'error',
							message: e
						})
					}
				}
			}
		},

		watch: {
			collectedData: {
				handler () {
					if (this.collectedData.phone && this.collectedData.phone == '(1__) ___-____') this.collectedData.phone = '(___) ___-____'
				},
				deep: true
			}
		}
	}
</script>



<style lang="scss" scoped>
	
	.signup-steps {
		display: block;
		width: 100%;
	}

	h1 {
		font-size: 52px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.06;
		letter-spacing: normal;
		color: var(--dark-grey-blue);
		margin-bottom: 30px;
	}
	.cta {
		max-width: 640px;
		.logo img {
			width: 47px;
		}
	}
	.cta-text {
		font-size: 17px;
		line-height: 1.71;
		color: var(--dark-indigo);
	}

</style>
