<template>
	<div class="doc-one" :data-filetype="getFileType(deal.data[documentData.name])" :class="{ 'is-loading': isLoading }">
		<div class="doc-preview"></div>
		<div class="doc-context">
			<div class="doc-header">
				<div class="doc-heading">{{ documentData.name }}</div>
				<div class="doc-hint"></div>
			</div>
			<div class="doc-footer" v-if="deal.data[documentData.name]">
				<input type="file" @change="handleFileChange">
				Uploaded
			</div>
			<div class="doc-footer" v-else>
				<input type="file" @change="handleFileChange">
				Not yet uploaded
			</div>
		</div>
	</div>
</template>



<script>

	import { mapActions } from 'vuex'
	
	export default {
		name: 'QuestionnaireDocument',

		props: {
			deal: Object,
			documentData: Object
		},

		data () {
			return {
				value: '',
				beenChanged: false,
				file: null,
				isLoading: false
			}
		},

		methods: {
			...mapActions([
				'updateDeal',
				'uploadFile',
				'notify'
			]),

			getFileType (file) {
				return file ? file.split('.').pop() : '';
			},

			handleFileChange (e) {
				if (!this.isLoading) {
					this.isLoading = true
					this.file = (e.target.files[0]) ? e.target.files[0] : null
					this.beenChanged = !!this.file
					this.processFileUpdate()
				}
			},

			async processFileUpdate () {
				try {
					const formData = new FormData()
							
					formData.append('file', this.file)
					formData.append('name', 'file')

					this.value = await this.uploadFile(formData)

					await this.processUpdate()
				} catch(e) {
					this.isLoading = false
					this.notify({
						type: 'error',
						message: e.error
					})
				}
			},

			async processUpdate () {
				try {
					await this.updateDeal({
						id: this.deal.id,
						data: { 
							[this.documentData.name]: this.value
						}
					})

					this.isLoading = false
					this.value = ''
					this.file = null
					this.$emit('document-updated', this.documentData._id)

					this.notify({
						message: 'Document was successfully uploaded'
					})

					this.beenChanged = false
				} catch(e) {
					console.log(e)
					this.isLoading = false
					this.notify({
						type: 'error',
						message: e.error || e.message || e
					})
				}
			},
		}
	}

</script>



<style lang="scss" scoped>
	
	.doc {
		&-one {
			width: 264px;
			border-radius: 16px;
			display: inline-block;
			margin: 7.5px;
			position: relative;
			user-select: none;
			transition: box-shadow 0.3s ease;
			cursor: pointer;
			&.is-loading {
				opacity: 0.6;
				pointer-events: none;
			}
			&:after {
				position: absolute;
				top:15px;
				right: 15px;
				width: 24px;
				height: 24px;
				margin: 0 0 1px 56px;
				border-radius: 15px;
				background: var(--powder-blue);
				/* background-color: var(--vermillion); */
				content: "";
			}
			&:hover {
				box-shadow: 0 25px 99px -21px rgba(51, 77, 97, 0.3);
			}
			.doc-preview {
				background: var(--very-light-blue) url('~@/assets/icons/icon-documents-placeholder.svg') center 40% no-repeat !important;
				position: relative;
				&:after {
					position: absolute;
					/* top:0; */
					left:0;
					right:0;
					bottom:14px;
					display: flex;
					align-items: center;
					justify-content: center;
					content: "Click to upload";
					color: var(--dark-indigo);
					font-size: 13px;
					opacity: 0.5;
				}
			}
			&[data-filetype]:not([data-filetype=""]) {
				.doc-preview {
					background: var(--very-light-blue) url('~@/assets/icons/icon-documents.svg') center no-repeat !important;
					&:after { 
						display: none;
					}
				}
				&:after {
					background:  var(--asparagus) url('~@/assets/icons/check-small-icon-light.svg') center no-repeat;
				}
			}
		}
		&-context {
			background: white;
			padding: 20px;
			height: 112px;
			box-sizing: border-box;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			border-radius: 0 0 16px 16px;
		}
		&-preview {
			background: var(--very-light-blue);
			border-radius: 16px 16px 0 0;
			padding-bottom: 68%;
		}
		&-heading {
			font-size: 16px;
			font-weight: 500;
			line-height: 1.38;
			color: var(--dark-indigo)
		}
		&-footer {
			font-size: 11px;
			font-weight: 500;
			color: var(--blue-grey);
			input {
				position: absolute;
				top: 0;
				left:0;
				bottom: 0;
				opacity: 0;
				width: 100%;
			}
		}
	}
	@media screen and (max-width: 664px){
		
		.doc-heading {
			font-size: 14px;
			font-weight: 500;
		}
		.doc-one {
			width: 100%;
			box-sizing: border-box;
			margin: 7.5px 0;
		}
		.doc-context {
			min-height: 102px;
		}
	}
</style>