<template>
	<div class="q" :id="`signup-step-${index}`" :class="{ 'is-previous': !isCurrent && !isToggled }">
		<form  @submit.prevent="handleFormSubmit" autocomplete="off">
			<div class="q-index">{{ displayedIndex || index + 1 }}</div>
			
			<div class="q-content">
				<div class="q-heading">
					{{ step.title }}
					<span class="q-heading__status green" v-if="!isCurrent"></span>
				</div>
				
				<!-- <div class="q-text" v-if="isExtended">{{ step.title }}</div> -->
				<div class="q-text" v-if="isExtended && step.hint">{{ step.hint }}</div>

				<div class="q-answers" v-if="isExtended">
					<template v-if="step.type == 'selector'">
						<div class="question-optionbnts-wrapper">
							<label
								v-for="(option, index) in step.options"
								:key="index"
								class="q-optionlabel"
								:class="{ 'is-active': value === option }"
							>
								<div class="check"></div>
								<input type="radio" :value="option" v-model="value" hidden>
								<img :src="getIcon(option)" :alt="option" v-if="getIcon(option)">
								<span>{{ option }}</span>
							</label>
						</div> 
					</template>

					<template v-else-if="step.type == 'form'">
						<div class="q-columns">
							<div class="q-input-wrapper grid" v-for="(field,index) in step.fields" :key="`field-${index}`">
								<span class="q-input-label">{{ field.label }}</span>
								<input
									type="text"
									:placeholder="field.placeholder"
									v-model="fields[field.name]"
									:name="field.name"
									:ref="field.name"
									:class="{ 'invalid': fields[field.name] && !formValidationState[field.name] }"
									v-if="field.name !== 'phone'"
								>
								<input
									v-else
									type="text"
									:placeholder="field.placeholder"
									v-model="fields[field.name]"
									name="phoneNumber"
									:ref="field.name"
									v-mask="field.mask"
									:class="{ 'invalid': fields[field.name] && !formValidationState[field.name] }"
									@keydown="handleAutofill($event, field.name)"
									autocomplete="tel-national"
								>
							</div>
						</div>

						<label class="q-footer checkbox-wrap">
							<input type="checkbox" name="terms-of-use" v-model="fields['terms-of-use']">
							<span class="icon"></span>
							<p v-html="step.footer"></p>
						</label>
					</template>

					<template v-else-if="step.type == 'date'">
						<div class="form-input-wrap form-date">
							<div
								v-for="field in ['month', 'day', 'year']" :key="field"
							>
								<label>
									<span>{{ field }}</span>
									
									<div class="select-wrap">
										<select
											v-model="value[field]"
											:class="{ 'muted': !value[field] }"
										>
											<option value="" :selected="true" disabled v-if="!value[field]">...</option>
											<option v-for="option in dateOptions[field]" :key="`${field}-${option}`">{{ option }}</option>
										</select>
									</div>
								</label>
							</div>
						</div>
					</template>

					<template v-else>
						<div
							class="q-input-wrapper"
							:class="{
								'currency': ['loanAmount','Loan amount', 'Annual Revenue'].includes(step.name)
							}"
						>
							<input
								type="text"
								:name="step.name"
								v-model="value"
								inputmode="numeric"
								autofocus
							>
						</div>
					</template>
				</div>

				<div class="q-actions" v-if="isExtended && step.type !== 'selector'">
					<button
						v-if="isLastStep"
						class="btn btn__primary"
						:disabled="!isValid || isLoading"
						type="submit"
						key="signup"
					>
						Submit
					</button>
					
					<button
						class="btn btn__primary"
						type="submit"
						v-else
						:disabled="!isValid || isLoading"
						key="next"
					>
						Next
					</button>
				</div>
			</div>
		</form>
	</div>
</template>



<script>

	import validations from '@/helpers/validations.js'
	import {mask} from 'vue-the-mask'
	import convertToCurrency from '@/helpers/convertToCurrency.js'
	import getIcon from '@/helpers/getIcon.js'
	import { mapGetters } from 'vuex'
	
	export default {
		name: 'SignUpFormStep',

		data () {
			return {
				value: '',
				fields: {},
				isToggled: false,
				maskInstances: {},
				titlesMap: {
					'propertyType': 'Property type',
					'loanAmount': 'Loan amount',
					'loanReason': 'Loan purpose'
				},
				dateOptions: {
					day: Array.from({length: 31}, (_, i) => ('0' + (i + 1)).slice(-2)),
					month: Array.from({length: 12}, (_, i) => ('0' + (i + 1)).slice(-2)),
					year: Array.from({length: 100}, (_, i) => new Date().getFullYear() - i)
				}
			}
		},
		
		directives: {
			mask
		},

		props: {
			step: {
				type: Object,
				required: true
			},
			index: {
				type: Number,
				required: true
			},
			displayedIndex: {
				type: Number,
				required: false
			},
			isCurrent: {
				type: Boolean,
				default: true
			},
			isLastStep: {
				type: Boolean,
				default: false
			},
			isLoading: {
				type: Boolean
			}
		},

		computed: {
			formValidationState () {
				if (this.step.type !== 'form') return {}

				let fieldsState = {}

				Object.values(this.step.fields).forEach(element => {
					let isValid = true

					element.validations.forEach(validation => {
						if (!validations[validation](this.fields[element.name])) isValid = false
					})

					fieldsState[element.name] = isValid
				})

				if (!this.fields['terms-of-use']) fieldsState['terms-of-use'] = false

				return fieldsState
			},

			isValid () {
				if (this.step.type == 'date') return this.value.day && this.value.month && this.value.year
				if (this.step.type !== 'form') return !!this.value
				else return !Object.values(this.formValidationState).filter(field => !field).length > 0
			},

			isExtended () {
				return this.isCurrent || this.isToggled
			},

			...mapGetters(['query'])
		},

		methods: {
			extend () {
				if (!this.isCurrent) this.isToggled = !this.isToggled
			},

			getIcon,

			handleFormSubmit () {
				if (this.isValid) {
					this.$emit('next-step', {
						index: this.index,
						name: this.step.name,
						value: this.value,
						fields: this.fields
					})
				}
			},

			handleAutofill (e, name) {
				setTimeout(() => {
					if (this.fields[name] !== e.target.value) this.fields[name] = e.target.value
				}, 100)
			},

			handleOptionClick () {
				this.$nextTick(() => {
					this.handleFormSubmit()
				})
			},
		},

		watch: {
			value () {
				if (this.step.type == 'selector' && this.value) this.handleFormSubmit()
				if (['loanAmount', 'Loan amount', 'Annual Revenue'].includes(this.step.name)) this.value = convertToCurrency(this.value)
			}
		},

		created () {
			if (this.query[this.step.name]) { 
				this.value = this.query[this.step.name]
			}
			
			if (!this.value && this.step.type == 'date') {
				this.value = {
					day: '',
					month: '',
					year: ''
				}
			} else if (this.step.type == 'form') {
				this.step.fields.forEach(element => {
					this.fields[element.name] = this.query[element.name] || ''
				})
				this.fields['terms-of-use'] = false
			}
		},

		mounted () {
			if (this.step.type == 'form') {
				this.step.fields.forEach(element => {
					// if (element.mask) {
					// 	this.maskInstances[element.name] = new Inputmask({mask:element.mask})
					// 	this.maskInstances[element.name].mask(this.$refs[element.name])
					// }
				})
			}
		}
	}

</script>

<style lang="scss" scoped>
	.form-date .select-wrap {
		margin-right: 10px;
	}
	.q {
		background-color: white;
		border-radius: 20px;
		padding: 35px 30px 30px 25px;
		width: 100%;
		box-sizing: border-box;

		&:not(:first-child) {
			margin-top: 20px;
		}

		form {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
		}

		&.is-previous {
			cursor: pointer;
			.q-index {
				color: var(--blue-grey);
				margin-top: 0;
			}

			.q-heading {
				font-size: 18px;
				color: var(--light-grey-blue);
				cursor: pointer;
			}
		}
		.currency {
			position: relative;
			&:after {
				content: "$";
				position: absolute;
				left: 22px;
				top:0px;
				height: 100%;
				display: flex;
				align-items: center;
				font-size: 15px;
				font-weight: 600;
				color: var(--blue-grey);
			}
		}
	}
	.q-heading {
		font-size: 35px;
		font-weight: 600;
		color: var(--dark-grey-blue);
		/* text-transform: capitalize; */
		position: relative;

		&__status {
			position: absolute;
			border-radius: 15px;
			width: 24px;
			height: 24px;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			display: block;

			&:before,
			&:after {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				display: block;
				content: "";
			}

			&.green {
				background: var(--light-sage);

				&:after {
					width: 6px;
					height: 3px;
					border: 2px solid var(--military-green);
					border-top: none;
					border-right: none;
					transform: rotate(-45deg);
				}
			}

			&.red {
				background: var(--light-peach);

				&:before,
				&:after {
					background: var(--dull-orange);
					width: 10px;
					height: 2px;
				}

				&:before {
					transform: rotate(45deg);
				}

				&:after {
					transform: rotate(-45deg);
				}
			}
		}
	}
	.q-index {
		width: 30px;
		height: 30px;
		border-radius: 15px;
		background-color: #f6f7fb; 
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-family: Poppins;
		font-size: 12px;
		font-weight: 600;
		color: var(--dark-grey-blue);
		margin-top: 12px;
		margin-right: 25px;
		flex: 0 0 30px;
	}

	.q-text {
		font-size: 15px;
		line-height: 26px;
		margin-top: 10px;
		max-width: 650px;
	}
	.q-content {
		width: 100%;
	}
	.q-answers {
		margin-top: 45px;
		&:last-child {
			margin-bottom: 25px;
		}
	}

	
	.q-optionlabel {
		padding: 26px 30px;
		border-radius: 16px;
		margin-right: 15px;
		margin-bottom: 15px;
		display: inline-block;
		width: calc(33% - 45px);
		position: relative;
		font-size: 15px;
		font-weight: 500;
		color: var(--dark-indigo);
		cursor: pointer;
		user-select: none;
		display: inline-flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		img {
			margin-right: 10px;
			width: 28px;
			height: 28px;
		}
		/* overflow: hidden; */
		.check {
			position: absolute;
			content: "";
			display: block;
			right: 0;
			top: 0;
			width: 52px;
			height: 52px;
			overflow: hidden;
			border-radius: 16px;
			transform: scale(0);
			transform-origin: top right;
			transition: transform 0.2s ease;
			&:after{
				position: absolute;
				content: "";
				display: block;
				right: 0;
				top: 0;
				margin-right: -26px;
				margin-top: -27px;
				width: 52px;
				height: 52px;
				border-radius: 16px;
				background-color: var(--orange);
			}
		}
		&.is-active {
			.check {
				transform: scale(1);
			}
		}
		&:after {
			content: "";
			position: absolute;
			left:0;
			right:0;
			bottom:0;
			top:0;
			z-index: 1;
			border-radius: 16px;
			box-shadow: 0 5px 33px -11px rgba(51, 77, 97, 0.21);
			transition: box-shadow 0.2s ease-out;
		}
		&:hover{
			&:after {
				box-shadow: 0 18px 70px -18px rgba(236, 130, 14, 0.5);
			}
		}
	}
	.question-optionbnts-wrapper {
		margin-bottom: -15px;
	}
	.q-columns {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: flex-start;
		max-width: 710px;
		margin: 0 -15px;

		/* & > *:nth-child(2n+1) {
			margin-right: 30px;
		}

		& > *:nth-child(n+3) {
			margin-top: 15px;
		} */
	}
	.q-input-wrapper.grid{
		width: 100%;
		max-width: 325px;
		margin: 7.5px 15px;
		input {
			width: 100%;
			box-sizing: border-box;
		}
	}
	.q-input-label {
		display: block;
		font-size: 13px;
		color: #344F63;
		letter-spacing: 0;
		padding-bottom: 8px;
	}
	.q-actions {
		margin-top: 40px;
		text-align: right;

		& > * + * {
			margin-left: 15px;
		}
	}
	.q-footer {
		font-size: 11px;
		color: #344F63;
		letter-spacing: 0;
		line-height: 19px;
		padding-top: 30px;
		max-width: 80%;
	}
	@media screen and (max-width: 664px) { 
		.intro-page .content.signup {
			/* padding: 20px 17px; */
		}
		.q{
			padding: 25px 20px;
			&-heading {
				font-size: 24px;
			}
			&-text {
				font-size: 14px;
				line-height: 1.5;
			}
			&-index {
				margin-top: 3px;
			}
			&-answers {
				margin-left: -55px;
				margin-top: 35px;
			}
			&-optionlabel {
				width: 100%;
				box-sizing: border-box;
				margin: 0 0 10px 0;
			}
			input[type=text]{
				width: 100%;
				box-sizing: border-box;
			}
			&-footer {
								padding-top: 0;
								max-width: 100%;
						}
		}
	}

	.checkbox-wrap {
		position: relative;
		padding-left: 40px;
		display: block;

		@media screen and (max-width: 664px) { 
			padding-top: 30px;
		}

		p {
			margin: 0;
			cursor: pointer;
		}

		.icon {
			background-image: url('~@/assets/icons/check-small-icon-light.svg');
			display: block;
			position: absolute;
			width: 17px;
			height: 18px;
			top: 36px;
			left: 8px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			opacity: 0;
			transition: opacity .3s ease-in-out;
			pointer-events: none;

			@media screen and (max-width: 640px) {
				top: 34px;
				left: 6px;
			}
		}
	}

	input[type="checkbox"] {
		appearance: none;
		position: absolute;
		width: 24px;
		height: 24px;
		border-radius: 6px;
		background-color: #f9fafd;
		border: 1px solid var(--powder-blue);
		left: 0;
		top: 30px;
		cursor: pointer;
		transition: .3s ease-in-out;

		@media screen and (max-width: 640px) {
			width: 20px;
			height: 20px;
		}

		&:checked {
			background-color: var(--orange);
			border-color: var(--orange) !important;

			& + .icon {
				opacity: 1;
			}
		}

		&:hover {
			border-color: var(--blue-grey);
		}
	}
</style>