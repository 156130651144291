<template>
	<div class="notification" :class="notification.type == 'error' ? 'red' : 'green'" v-if="isVisible">
		<span class="icon"></span>
		<p>{{ notification.message }}</p>
		<button type="button" class="notification-close" @click="isVisible=false"></button>
	</div>
</template>

<script>
	
	export default {
		name: 'NotificationSingle',

		props: {
			notification: {
				type: Object
			}
		},

		data () {
			return {
				isVisible: true
			}
		},

		mounted () {
			setTimeout(() => {
				this.isVisible = false
			}, 5000)
		}
	}

</script>

<style lang="scss">
	
	.notification {
		background: white;
		display: flex;
		flex-direction: row;
		width: 100%;
		padding: 34px 70px 34px 35px;
		align-items: center;
		justify-content: flex-start;
		box-sizing: border-box;
		border-radius: 16px;
		box-shadow: 0 25px 99px -32px rgba(51,77,97,0.44);
		position: relative;

		&-close {
			width: 25px;
			height: 25px;
			background: var(--pale-grey);
			border-radius: 6px;
			position: absolute;
			position: absolute;
			top: 20px;
			right: 20px;
			cursor: pointer;
			transition: background .2s ease-in-out;
			border: none;

			&:before,
			&:after {
				width: 10px;
				height: 2px;
				border-radius: 1px;
				display: block;
				content: "";
				background: #344F63;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				margin: auto;
				transform: rotate(45deg);
			}

			&:after {
				transform: rotate(-45deg);
			}

			&:hover {
				background: var(--very-light-blue);
			}
		}

		.icon {
			width: 36px;
			height: 36px;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			border-radius: 100%;
			margin-right: 25px;
			background: var(--asparagus);
			flex-shrink: 0;

			&:before {
				width: 4px;
				height: 6px;
				border: 2px solid white;
				border-top: none;
				border-left: none;
				transform: rotate(45deg);
				display: block;
				content: "";
			}
		}

		&.red {
			.icon {
				background: var(--vermillion);

				&:before {
					display: none;
				}

				&:after {
					display: block;
					content: "!";
					color: white;
					font-size: 13px;
					font-weight: bold;
				}
			}
		}

		p {
			font-weight: 600;
			font-size: 16px;
			color: #344F63;
			letter-spacing: 0;
			line-height: 22px;
		}
	}

</style>