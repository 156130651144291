<template>
	
	<div class="modal-text">
		<h2>You’re done!</h2>
		<p>You've answered all the questions and uploaded all requested documents, great job! It’s up to our algorithms and team to take it from here. We’ll be back in touch shortly with our results.</p>
	</div>
	<div class="modal-btns">
		<button type="button" class="btn" @click="submitDeal">Close</button>
	</div>
	<!-- <button type="button" @click="closeModal" :disabled="isLoading">No</button> -->
	
</template>



<script>

	import { mapActions, mapGetters } from 'vuex'
	
	export default {
		name: 'ModalSubmitDeal',

		data () {
			return {
				isLoading: false
			}
		},

		props: {
			modalData: {
				type: Object
			}
		},

		computed: {
			...mapGetters([
				'customer'
			])
		},

		methods: {
			...mapActions([
				'updateDeal',
				'notify'
			]),

			closeModal () {
				this.$emit('closed')
			},

			async submitDeal () {
				if (!this.isLoading) {
					try {
						// await this.updateDeal({
						// 	id: this.modalData.dealId,
						// 	submitted: true
						// })

						// mixpanel.track("Deal submitted", {
						// 	"Deal ID": this.modalData.dealId
						// })

						// window._hsq.push(["identify",{
						// 	email: this.customer.email,
						// 	last_deal_summary: `https://lenders.janover.ventures/deal/${this.modalData.dealId}`
						// }]);

						// window._hsq.push(["trackEvent", { 
						// 	id: "Portal – Deal submitted"
						// }]);

						// this.notify({
						// 	message: 'You have successfuly submitted the deal'
						// })

						this.$router.push('/')

						this.closeModal()
					} catch(e) {
						console.log(e)

						this.notify({
							type: 'error',
							message: e
						})
						window.analytics.track("Portal Error",
							{
								"Error": e
							}
						)
					}
				}
			},
		}
	}
</script>



<style lang="scss">
	
	.modal {
		width: 400px;
		background: white;
		padding: 40px;
		box-sizing: border-box;
	}
</style>