export default {
  category: 'birthDate',

  fields: {
    month: {
      model: 'month',
      type: 'select',
      options: Array.from({length: 12}, (_, i) => ('0' + (i + 1)).slice(-2)),
      label: 'Month',
      placeholder: '01',
      validations: [],
      editable: true
    },
    
    day: {
      model: 'day',
      type: 'select',
      options: Array.from({length: 31}, (_, i) => ('0' + (i + 1)).slice(-2)),
      label: 'Day',
      placeholder: '01',
      validations: [],
      editable: true
    },

    year: {
      model: 'year',
      type: 'select',
      options: Array.from({length: 82}, (_, i) => new Date().getFullYear() - 18 - i),
      label: 'Year',
      placeholder: '2000',
      validations: [],
      editable: true
    }
  }
}