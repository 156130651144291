import axios from 'axios'

import logger from '@/helpers/logger'

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' || window.location.origin.includes('staging') ? process.env.VUE_APP_API_URL_STAGE : process.env.VUE_APP_API_URL
})

export default {
  setAuthorizationHeaders (token) {
    logger('api/setAuthorizationHeaders', !!token)

    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  },

  async authorizeCustomerByOneTimeCode (payload) {
    try {
      const { data } = await instance.post('/login-fast', payload)

      if (data.token) {
        this.setAuthorizationHeaders(data.token)
      }

      return data
    } catch(e) {
      console.log(e.response)
      throw e.response.data
    }
  },

  async login (payload) {
    logger('api/login', payload)

    try {
      const { data } = await instance.post('/login', payload)

      if (data.token) {
        this.setAuthorizationHeaders(data.token)
      }

      return data
    } catch(e) {
      console.log(e.response)
      throw e.response.data
    }
  },

  async loginByEmail (payload) {
    logger('api/loginByEmail', payload)

    try {
      const { data } = await instance.post(`/magic-login`, payload)

      return !data.error
    } catch(e) {
      console.log(e.response)
      throw e.response.data
    }
  },

  async getCustomer () {
    logger('api/getCustomer')

    const { data } = await instance.get(`/customer`)

    return data.profile
  },

  async createCustomer (payload) {
    logger('api/createCustomer')

    try {
      const { data } = await instance.post(`/customer-internal`, payload)

      return data
    } catch(e) {
      console.log(e.response)
      throw e.response.data
    }
  },

  async updateCustomer (payload) {
    logger('api/updateCustomer', payload)

    try {
      const { data } = await instance.post(`/customer/update`, payload)

      return data.profile
    } catch(e) {
      console.log(e.response)
      throw e.response.data
    }
  },

  async updatePassword (payload) {
    logger('api/updatePassword', payload)

    try {
      const { data } = await instance.post(`/customer/update-password`, payload)

      return data.token
    } catch(e) {
      console.log(e.response)
      throw e.response.data
    }
  },

  async requestPasswordUpdate (payload) {
    logger('api/requestPasswordUpdate', payload)

    try {
      const { data } = await instance.post(`/customer/request-password`, payload)

      return !data.error
    } catch(e) {
      console.log(e.response)
      throw e.response.data
    }
  },

  async uploadFile (payload) {
    logger('api/uploadFile')

    try {
      const { data } = await instance.post(`/file`, payload)

      return data.path
    } catch(e) {
      console.log(e.response)
      throw e.response.data
    }
  },

  async getFields () {
    logger('api/getFields')

    try {
      const { data } = await instance.get(`/fields`)

      return data
    } catch(e) {
      console.log(e.response)
      throw e.response.data
    }
  },

  async getQuestionnaireById (id) {
    logger('api/getQuestionnaireById', id)

    try {
      const { data } = await instance.get(`/flow?id=${id}`)

      return data.scheme
    } catch(e) {
      console.log(e.response)
      throw e.response.data
    }
  },

  async getQuestionnaireByType (type) {
    logger('api/getQuestionnaireByType', type)

    try {
      const { data } = await instance.get(`/flow?type=${type}`)

      return data.scheme
    } catch(e) {
      console.log(e.response)
      throw e.response.data
    }
  },

  async getQuestions () {
    logger('api/getQuestions')

    try {
      const { data } = await instance.get(`/fields`)

      return data.fields
    } catch(e) {
      console.log(e.response)
      throw e.response.data
    }
  },

  async createDeal (payload) {
    logger('api/createDeal', payload)

    try {
      const { data } = await instance.post(`/deal`, payload)

      return data.deal
    } catch(e) {
      console.log(e.response)
      throw e.response.data
    }
  },

  async getDeal (id) {
    logger('api/getDeal', id)

    try {
      const { data } = await instance.get(`/deal?id=${id}`)

      return data.deal
    } catch(e) {
      console.log(e.response)
      throw e.response.data
    }
  },

  async updateDeal (id, payload) {
    logger('api/updateDeal', id, payload)

    try {
      const { data } = await instance.post(`/deal/${id}`, payload)

      return data.deal
    } catch(e) {
      console.log(e.response)
      throw e.response.data
    }
  },

  async getDealSchema (dealId) {
    logger('api/getDealSchema', dealId)

    try {
      const { data } = await instance.get(`/schema/${dealId}`)

      return data.schema
    } catch(e) {
      console.log(e.response)
      throw e.response.data
    }
  }
}