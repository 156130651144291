/* eslint-disable */
export default {
	required (value) {
		if (typeof value === 'object') return Object.keys(value).length > 0
		return Boolean(value)
	},

	isEmail (value) {
		const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return regex.test(value)
	},

	isPassword (value) {
		return value.length > 5
	},

	isСonfirmation (value, source) {
		return value === source
	},

	isImage (file) {
		const regexExtetion = /.(?:jpg|gif|png|jpeg)/
		const regexType = /image/

		return regexExtetion.test(file.name) && regexType.test(file.type)
	},

	isPhone (value) {
		const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
		return regex.test(value)
	},

	isNameString (value) {
		return /^[A-Za-z\s]+$/.test(value)
	}
}