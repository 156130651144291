<template>
	<div class="profile">
		<h1 class="profile-heading">Your profile</h1>
		
		<div class="profile-nav">
			<a
				v-for="(value, key) in profileTabs"
				:href="key"
				:key="key"
				:class="{ 'is-active': activeTab == key }"
			>
				{{ value }}
			</a>
		</div>
		
		<template v-if="activeTab == '#general'">
			<profile-avatar />
			<profile-generic-form :config="PersonalInfoConfig" title="Personal info" />
			<profile-generic-form :config="BirthDateConfig" title="Date of birth" />
			<profile-generic-form :config="BusinessAddressConfig" title="Business address" />
			<profile-generic-form :config="HomeAddressConfig" title="Home address" />
		</template>
		
		<template v-else-if="activeTab == '#social'">
			<profile-generic-form :config="SocialMediaConfig" title="Social media" />
		</template>
	</div>
</template>



<script>

	import ProfileAvatar from '@/components/ProfileAvatar'
	import ProfileGenericForm from '@/components/ProfileGenericForm'

	import PersonalInfoConfig from '@/config/forms/personal-info'
	import BirthDateConfig from '@/config/forms/birth-date'
	import BusinessAddressConfig from '@/config/forms/business-address'
	import HomeAddressConfig from '@/config/forms/home-address'
	import SocialMediaConfig from '@/config/forms/social-media'

	import profileTabs from '@/constants/profile-tabs.js'

	export default {
		name: 'PageProfile',

		components: {
			ProfileAvatar,
			ProfileGenericForm
		},

		data () {
			return {
				PersonalInfoConfig,
				BusinessAddressConfig,
				HomeAddressConfig,
				SocialMediaConfig,
				BirthDateConfig,
				profileTabs
			}
		},

		computed: {
			activeTab () {
				return this.$route.hash || Object.keys(this.profileTabs)[0]
			}
		}
	}

</script>



<style lang="scss" scoped>

	.profile-heading {
		font-size: 35px;
		font-weight: 600;
		text-align: center;
		color: var(--dark-grey-blue);
		margin-bottom: 35px;
	}

	.profile-nav {
		display: flex;
		width: 100%;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid var(--very-light-blue);
		margin-bottom: 40px;

		& > * + * {
			margin-left: 20px;
		}

		a {
			font-weight: 500;
			font-size: 13px;
			color: var(--blue-grey);
			letter-spacing: 0;
			transition: color .3s ease-in-out;
			display: block;
			line-height: 1;
			padding-bottom: 10px;
			position: relative;

			&:hover,
			&.is-active {
				color: var(--dark-grey-blue);
			}

			&.is-active {
				&:after {
					display: block;
					content: "";
					position: absolute;
					top: 100%;
					height: 1px;
					width: 100%;
					left: 0;
					background-color: var(--dark-grey-blue);
					border-radius: 1px;
				}
			}
		}
	}
	
	.profile {
		padding: 40px 0 0;
		text-align: center;

		form {
			text-align: left;
			width: 100%;
			max-width: 753px;
			box-sizing: border-box;
			margin: 15px auto;
			padding: 50px 30px 30px 70px;
			border-radius: 20px;
			background-color: #ffffff;
		}
	}
	@media screen and (max-width: 664px){
		.profile {
			padding: 40px 17px;
			form {
				padding: 40px 20px 20px;
			}
		}
		.profile-heading {
			font-size: 24px;
			font-weight: 600;
			line-height: 1.17;
		}
	}

</style>