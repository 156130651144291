<template>
	<div class="notifications">
		<notification-single
			v-for="(notification, index) in notifications"
			:notification="notification"
			:key="index"
		/>
	</div>
</template>



<script>
	
	import { mapGetters } from 'vuex'

	import NotificationSingle from '@/components/NotificationSingle'

	export default {
		name: 'NotificationsBar',

		computed: {
			...mapGetters([
				'notifications'
			])
		},

		components: {
			NotificationSingle
		}
	}

</script>



<style lang="scss" scoped>
	
	.notifications {
		position: fixed;
		z-index: 99;
		bottom: 30px;
		right: 30px;
		width: 360px;
		max-width: calc(100% - 60px);

		& > * + * {
			margin-top: 10px;
		}
	}

</style>