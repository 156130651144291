import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import './index.css'


Sentry.init({
    App,
    dsn: "https://1a59f4c8b6904473802a0a489e231690@o666436.ingest.sentry.io/5766378",
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "portal.janover.ventures", /^\//],
        }),
    ],
    tracesSampleRate: 1.0,
    logErrors: true
});

createApp(App)
    .use(store)
    .use(router)
    .mount('#app')