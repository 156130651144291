import numeral from 'numeral'

export default function (deal) {
	let dealName = ''

	if (deal.data && deal.type !== 'Business') {
		if (deal.data['Loan amount']) dealName += `${numeral(deal.data['Loan amount']).format('$0a').toUpperCase()} `
		if (deal.data['Property type']) dealName += deal.data['Property type'].replace('5+ Units', '').replace('1-4 Unit', '')
		if (deal.data['Loan purpose']) dealName += ` ${deal.data['Loan purpose']}`
	}

	return dealName || 'New Deal'
}