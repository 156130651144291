<template>
	<div v-if="deals && deals.length > 0">
		<questionnaire v-if="deal" :deal="deal" />
	</div>
</template>



<script>
	
	import { mapGetters, mapActions } from 'vuex'

	import Questionnaire from '@/components/Questionnaire'

	export default {
		name: 'PageDeal',

		components: {
			Questionnaire
		},

		computed: {
			...mapGetters([
				'deals'
			]),

			id () {
				return this.$route.params.id
			},

			deal () {
				return this.deals ? this.deals.filter(deal => {
					return deal.id == this.id
				})[0] : null
			}
		},

		methods: {
			...mapActions([
				'getDeals'
			])
		},

		created () {
			if (!this.deals || this.deals.length < 1) this.getDeals()
		}
	}

</script>