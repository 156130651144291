import { createRouter, createWebHistory } from 'vue-router'

import Auth from '@/views/Auth'
import Dashboard from '@/views/Dashboard'
import Deal from '@/views/Deal'
import Login from '@/views/Login'
import Profile from '@/views/Profile'
import SignUp from '@/views/SignUp'
import ExternalSignUp from '@/views/ExternalSignUp'
import Message from '@/views/Message'
import OneTimeAuth from '@/views/OneTimeAuth'

import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/sign-in',
    name: 'Login',
    component: Login
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/deal/:id',
    name: 'Deal',
    component: Deal,
    meta: { requiresAuth: true }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: { requiresAuth: true }
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/external-sign-up',
    name: 'ExternalSignUp',
    component: ExternalSignUp
  },
  {
    path: '/message',
    name: 'Message',
    component: Message
  },
  {
    path: '/one-time-auth',
    name: 'OneTimeAuth',
    component: OneTimeAuth
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (!store.getters['appIsReady']) {
    await store.dispatch('init')
  }
  
  window._hsq.push(['setPath', to.path]);
  window._hsq.push(['trackPageView']);

  if (to.meta.requiresAuth) {
    if (!store.getters['isAuth']) next({ name: 'Login', query: { intro: true } })
    else next()
  } else if (!to.meta.requiresAuth && store.getters['isAuth']) next({ name: 'Dashboard' })

  else next()
})

router.afterEach(async () => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.get('aid') && window.analytics.setAnonymousId && window.analytics.setAnonymousId(urlParams.get('aid'));
  window.analytics.page({
    hostname: window.location.hostname.replace("www.", "")
  });
})

export default router
