<template>
	<div>
		<!-- Waiting for authorization... -->
	</div>
</template>



<script>
	
	import { mapActions, mapGetters } from 'vuex'
	import validations from '@/helpers/validations.js'

	export default {
		name: 'PageAuth',

		computed: {
			...mapGetters([
				'isAuth',
				'customer'
			])
		},

		methods: {
			...mapActions([
				'notify',
				'createCustomer',
				'showModal'
			]),

			validate (payload) {
				let isValid = true

				for (let key in this.validationConfig) {
					this.validationConfig[key].forEach(validation => {
						if (!payload[key]) isValid = false
						else if (!validations[validation](payload[key])) isValid = false
					})
				}

				return isValid
			} 
		},

		data () {
			return {
				validationConfig: { 
					propertyType: ['required'],
					loanReason: ['required'],
					loanAmount: ['required'],
					firstName: ['required', 'isNameString'],
					lastName: ['required', 'isNameString'],
					phone: ['required', 'isPhone'],
					email: ['required', 'isEmail']
				}
			}
		},

		watch: {
			isAuth () {
				if (this.isAuth) {
					const { __hstc, __hssc, __hsfp, hutk } = this.$route.query;
					const hubspotString = __hstc && __hssc && __hsfp && hutk ? "?__hstc="+ __hstc +"&__hssc="+ __hssc +"&__hsfp="+ __hsfp +"&hutk="+ hutk : "" ;
					this.$router.push(`/deal/${this.customer.deals[0]}${hubspotString}`)
					this.showModal({
						name: 'Welcome'
					})
				}
			}
		},

		async mounted () {
			if (this.validate(this.$route.query)) {
				const response = await this.createCustomer(this.$route.query)
			
				if (!response) {
					this.notify({
						type: 'error',
						message: 'Account with provided email was already registered'
					})
				}
			} else {
				this.notify({
					type: 'error',
					message: 'Invalid parameters'
				})
				window.analytics.track("Portal Error",
					{
						"Error": 'Invalid parameters'
					}
				)

				this.$router.push('/')
			}
		}
	}
</script>