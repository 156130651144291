<template>
  <nav v-if="appIsReady && isAuth" class="mainnav" :class="{ 'state-dark': $route.name === 'Dashboard' }">
    <div class="left">
      <router-link class="logo" to="/">
        <img class="logo-dark" src="@/assets/logo-full-dark.svg" alt="Janover">
        <img class="logo-light" src="@/assets/logo-full-white.svg" alt="Janover">
      </router-link>
      <div class="mainnav-inner">
        <!-- @TODO: remove hardcoded stuff -->
        <div class="breadcrumbs" v-if="$route.name == 'Profile'">
          <span class="breadcrumbs-item">Profile</span>
          <span class="breadcrumbs-item" v-if="activeTab">{{ activeTab }}</span>
        </div>

        <div class="breadcrumbs" v-if="$route.name == 'Dashboard'">
          <span class="breadcrumbs-item white">My requests</span>
        </div>

        <div class="breadcrumbs" v-if="$route.name == 'Deal'">
          <router-link to="/" class="breadcrumbs-item">My requests</router-link>
          <span class="breadcrumbs-item" v-if="deal">{{ getDealName(deal) }}</span>
        </div>
        <!-- end of @TODO -->
      </div>
    </div>
    <div class="menu">
      <div class="hover-zone">
        <div class="menu-toggle">
          <div class="avatar">
            <img v-if="customer && customer.image" :src="customer.image" alt="Profile picture">
          </div>
          <div class="drop"><img src="@/assets/icons/profile-arrow.svg" alt=""></div>
        </div>
      
        <div class="drop-area-wrapper">
          <div class="drop-area">
            <div class="user-info">
              <div class="avatar">
                <img v-if="customer && customer.image" :src="customer.image" alt="Profile picture">
              </div> 
              <div>
                <div class="name">{{ customer.firstName }} {{ customer.lastName }}</div>
                <div class="email">{{ customer.email }}</div>
              </div>
            </div>
            <div class="menu-items">
              <router-link to="/">Dashboard</router-link>
              <router-link to="/profile">Profile</router-link>
              <br>
              <a @click="logout()">Logout</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <div id="view">
    <router-view v-if="appIsReady" />
  </div>  
  <div class="footer" v-if="appIsReady && isAuth">
    <div class="copyright">Copyright 2022 © Janover Inc.</div>
  </div>
  <notifications-bar />
  <modals-bar />
</template>


<script>

  import { mapActions, mapGetters } from 'vuex'
  import profileTabs from '@/constants/profile-tabs.js'
  import getDealName from '@/helpers/getDealName.js'
  import NotificationsBar from '@/components/NotificationsBar'
  import ModalsBar from '@/components/ModalsBar'
  
  
  export default {
    name: 'App',

    computed: {
      ...mapGetters([
        'isAuth',
        'appIsReady',
        'deals',
        'customer'
      ]),

      activeTab () {
        if (!this.$route.hash && !profileTabs[this.$route.hash]) return false
        return profileTabs[this.$route.hash]
      },

      deal () {
        return this.deals ? this.deals.filter(deal => {
          return deal.id == this.$route.params.id
        })[0] : null
      }
    },

    components: {
      NotificationsBar,
      ModalsBar
    },

    methods: {
      ...mapActions([
        'init',
        'deauthorizeCustomer',
        'showModal'
      ]),

      getDealName,

      async logout () {
        await this.deauthorizeCustomer();
      }
    },

    mounted () {
      if (window && window.location.search.includes('welcome') && !window.location.search.includes('returning-customer')) {
        this.showModal({
          name: 'Welcome'
        })
      }
    }
  }

</script>

<style src="@/assets/fonts.scss" lang="scss"></style>

<style lang="scss">

:root {
  --dark-grey-blue: #344f63;
  --dark-indigo: #0b233d;
  --blue-grey: #8394a1;
  --vermillion: #e7421d;
  --pale-grey: #f6f7fb;
  --orange: #ec820e;
  --dull-orange: #d18d45;
  --light-peach: #f6dbbe;
  --military-green: #698646;
  --powder-blue: #d4dce2;
  --very-light-blue: #e6eaf1;
  --light-sage: #d0e3bb;
  --asparagus: #88af5a;
  --light-grey-blue: #aeb9c1;
  --sky-blue: #78a0f7;
  --rusty-red: #c02f0f;
  --dirty-orange: #d27006;
}

html {
    -webkit-text-size-adjust: none
}

#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins';
}
input {
  font-family: 'Poppins';
}
body {
  margin: 0;
  background-color: var(--pale-grey);
}
.mainnav {
  padding: 24px 30px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-height: 68px;
  box-sizing: border-box;
  font-size: 13px;
  position: -webkit-sticky;
  position: sticky;
  top:0;
  z-index: 99999;
  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .logo-light {
    display:none;
  }
  &.state-dark {
    background-color: var(--dark-grey-blue);
    a {
      color: white;  
    }
    .logo-dark {
      display:none;
    }
    .logo-light {
      display:block;
    }
  }

  .btn {
    height: 35px;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 0 15px;
    line-height: 35px;
    font-size: 13px;
    margin-left: 10px;
  }
}
.logo img {
  display: block;
}
a {
  text-decoration: none;
}
.mainnav-inner {
  margin-left: 40px;
}
nav a + a {
  margin-left: 40px;
}

.btn, button.btn {
  appearance: none;
  border-radius: 16px;
  background-color: var(--orange);
  color: white;
  padding: 16px 30px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  font-family: 'Poppins';
  outline: none;
  cursor: pointer;
  display: inline-block;
  &:hover {
    background-color: var(--dirty-orange);
  }

  &:disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  &__secondary {
    background-color: var(--very-light-blue);
    color: var(--dark-indigo);

    &:hover {
      background-color: var(--powder-blue);
    }
  }

  &__skip{
    appearance: none;
    -webkit-appearance: none;
    background: none;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.46;
    text-align: center;
    color: var(--blue-grey);
    text-decoration: underline;
    padding-left: 0;
    padding-right: 0;

    &:hover {
      background-color: transparent;
      text-decoration: none;
    }
  }

  &__back {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.46;
    text-align: center;
    color: var(--dark-grey-blue);
    background-color: transparent;
    .icon-back { 
      margin-right: 6px;
    }
    /* .icon */
    &:hover {
      background-color: transparent;
      text-decoration: none;
      .icon-back {
        background: var(--powder-blue) url('~@/assets/icons/select-arrow.svg') center no-repeat;
      }
    }
  }

  &__next {
    .icon-next {
      filter: brightness(1500%);
      margin: 0 0 0 12px;
      margin-top: -.2em;
    }
  }
  &__dark {
    background-color: var(--dark-grey-blue);

    &:hover {
      background-color: var(--dark-indigo);
    }
  }

  &__small {
    padding: 8px 15px;
    border-radius: 12px;
  }
}

.icon-back {
  width: 25px;
  height: 25px;
  border-radius: 6px;
  /* background-color: var(--very-light-blue); */
  /* position: absolute; */
  background: var(--very-light-blue) url('~@/assets/icons/select-arrow.svg') center no-repeat;
  /* margin-top: -12px; */
  margin-top: -0.2em;
  transform: rotate(90deg); 
  display: inline-block;
  vertical-align: middle;

  &:hover {
    background: var(--powder-blue) url('~@/assets/icons/select-arrow.svg') center no-repeat;
  }
}

.icon-next {
  width: 8px;
  height: 7px;
  border-radius: 6px;
  display: inline-block;
  vertical-align: middle;
  /* background-color: var(--very-light-blue); */
  /* position: absolute; */
  background: url('~@/assets/icons/select-arrow.svg') center no-repeat;
}

.buttons-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  & > * + * {
    margin-left: 15px !important;
  }
}
.container {
  max-width: 1290px;
  margin: 0 auto;
  padding: 0 30px;
}
input[type="text"], input[type="email"], textarea {
  border-radius: 10px;
  border: solid 1px var(--powder-blue);
  background-color: #f9fafd;
  padding: 19px 25px 18px;
  outline: none;
  color: var(--dark-grey-blue);
  font-size: 17px;
  /* font-weight: 500; */
  -webkit-font-smoothing: auto;
  ::placeholder {
    color: var(--light-grey-blue);
  }
  &:hover, &:focus {
    border-color: var(--blue-grey);
  }
  &.invalid {
    border-color: var(--rusty-red);
  }
}
textarea {
  width: 100%;
  max-width: 30em;
  min-height: 5em;
  max-width: 100%;
  box-sizing: border-box;
}
input[inputmode="numeric"]{
  font-weight: 500;
  padding-left: 40px;
}
input::placeholder {
  color: var(--light-grey-blue);
}
.progress-bar {
  &-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-top: 10px;
  }
  &, &_done {
    height: 3px;
    border-radius: 4px;
    background-color: var(--very-light-blue);
    width: 100%;
  }
  &_done {
    width: 0%;
    background-color: var(--asparagus);
  }
  &-legend {
    font-size: 11px;
    font-weight: 500;
    text-align: right;
    color: var(--blue-grey);
    flex: 0 0 40px;
  }
}
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
#view {
  flex: 1;
}
.footer {
  padding: 30px;
  margin-top: 50px;
  .copyright {
    font-size: 11px;
    font-weight: 500;
    color: var(--dark-grey-blue);
  }
}
.color-accent {
  color: var(--orange)
}
.breadcrumbs-item {
  font-size: 13px;
  color: #344F63;
  letter-spacing: 0;
  line-height: 1;
  display: block;
  &.white {
    color: white;
  }
}
.breadcrumbs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & > * + * {
    margin-left: 16px;
    position: relative;

    &:before {
      position: absolute;
      right: calc(100% + 6px);
      top: 0;
      width: 4px;
      height: 4px;
      border: 1px solid #AEB9C1;
      display: block;
      content: "";
      transform: rotate(45deg);
      border-left: none;
      border-bottom: none;
      bottom: 0;
      margin: auto;
    }
  }

  a {
    transition: color .3s ease-in-out;
  }

  a:hover {
    color: #0B233D;
  }
}

.menu {
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    overflow: hidden;
    /* border: solid 1px #ffffff; */
    background: var(--very-light-blue) url('~@/assets/icons/avatar-placeholder.svg') center no-repeat;
    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }
  .name {
    font-size: 15px;
    font-weight: 500;
    /* line-height: 1.73; */
    color: var(--dark-indigo);
  }
  .email {
    font-size: 11px;
    font-weight: 500;
    color: var(--blue-grey);
  }
  .hover-zone {
    position: relative;
    .menu-toggle{
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .drop {
      margin-left: 10px;
    }
    &:hover {
      .drop-area-wrapper {
        display: block;
      }
      .drop{
        transform: rotate(180deg)
      }
    }
  }
  .drop-area {
    &-wrapper {
      padding-top: 25px;
      position: absolute;
      right: 0px;
      top: 100%;
      display: none;
      z-index: 9999;
    }
    background: white;
    padding: 30px;
    border-radius: 16px;
    box-shadow: 0 10px 91px -39px rgba(51, 77, 97, 0.43);
  }
  .user-info {
    display: flex;
    align-items: center;
    .avatar {
      margin-right: 20px;
    }
  }
  .menu-items {
    margin-top: 17px;
    a {
      font-size: 15px;
      line-height: 2.2;
      color: var(--dark-grey-blue);
      display: block;
      margin: 0;
      cursor: pointer;
      &:hover {
        color: var(--dark-indigo);
      }
    }
  }
}
.state-dark {
  .drop {
    filter: invert(1) brightness(200);
    -webkit-filter: invert(1) brightness(200);
  }
}
.breadcrumbs span, .dashboard-welcome {
  cursor: default;
}
.form-date {
  display: flex;
  flex-direction: row;
  label {
    flex-direction: column;
    align-items: flex-start;
  }
  span {
    display: block;
  }
  label {
    flex-direction: column;
    align-items: flex-start;
    span {
      display: block;
      font-size: 13px;
      color: var(--dark-grey-blue);
      text-transform: capitalize;
    }
  }
  select {
    border-radius: 10px;
    border: solid 1px #e2e6ec;
    background-color: #f7f8fc;
    width: 160px;
    height: 60px;
    padding: 20px 50px 20px 25px;
    -webkit-appearance: none;
    appearance: none;
    font-size: 15px;
    outline:none;
    &:hover, &:focus {
      border-color: var(--blue-grey);
    }
    /* color: var(--light-grey-blue); */
  }
}
@media screen and (min-width: 665px){
  .small-only {
    display: none;
  }
}
@media screen and (max-width: 664px){
  .big-only {
    display: none;
  }
  .mainnav.state-dark .breadcrumbs{
    display: none;
  }
  .btn {
    padding: 12px 25px;
    font-size: 14px;
  }
  textarea {
    /* width: calc(100% - 50px); */
  }
  .footer {
    margin-top: 0;
  }
  .form-date {
    justify-content: space-between;
    select {
      width: 100%;
      font-size: 14px;
      padding: 15px;
      height: auto; 
      min-width: 90px;
    }
    .select-wrap:after {
      transform: scale(0.84);
      right: 15px;
    }
    .select-wrap {
      margin-right: 0 !important;
    }
  }
}
@media screen and (min-width: 665px) and (max-height: 600px){ 
  .footer {
    margin-top: 0;
  }
}
</style>
