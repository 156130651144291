import api from '@/services/api'
import logger from '@/helpers/logger'

export default {
	namespaced: false,

	state: () => {
		return {
			deals: [],
			questions: null,
		}
	},

	getters: {
		dealIds (state, getters, rootState) {
			return rootState.customer.deals
		},

		deals (state) {
			return state.deals
		},

		questions (state) {
			let questionsAsObject = {}
			
			state.questions.forEach((element) => {
				questionsAsObject[element._id] = element				
			})

			return questionsAsObject
		}
	},

	actions: {
		async createDeal ({ dispatch }, type) {
			const payload =  {
				type
			}

			const deal = await api.createDeal(payload)

			await dispatch('getCustomer')
			await dispatch('getDeals')
			
			return deal
		},

		async getDeals ({ getters, dispatch, commit }) {
			logger('ACTION: getDeals')

			await dispatch('getQuestions')

			return new Promise((resolve) => {
				Promise.all(getters.dealIds.map(dealId => api.getDeal(dealId))).then((deals) => {
					commit('updateDeals', deals)
					resolve()
				})
			})
		},

		async getQuestions ({ commit }) {
			const questions = await api.getQuestions()

			commit('updateQuestions', questions)
		},

		async updateDeal ({ dispatch }, { id, data, questionsStory, submitted, estimatedProgress, archived }) {
			logger('ACTION: updateDeal', data, questionsStory)

			const deal = await api.updateDeal(id, {
				data,
				questionsStory,
				submitted,
				estimatedProgress,
				archived
			})

			await dispatch('getDeals')

			return !!deal
		}
	},

	mutations: {
		updateDeals (state, deals) {
			logger('MUTATION: updateDeals')

			state.deals = deals
		},

		updateQuestions (state, questions) {
			logger('MUTATION: updateQuestions')

			state.questions = questions
		}
	}
}